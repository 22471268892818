import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectContract } from '../../../../src/contractSelect/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { selectContructionSite } from '../../../../src/constructionSiteSelect/Actions';
import List from '../../../../src/list/List';
import { fetch, timer } from '../utils';
import { fromJS } from 'immutable';


class Trucks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trucks: [],
      loading: true,
      header: ['Rekisteri-numero', 'Vakio massa määrä', ''],
      fields: ['register_number', 'default_mass',
               'button#Muokkaa#/truck/{id}']
    };

    this.getTrucks = this.getTrucks.bind(this);
    this.removeTruckFromSite = this.removeTruckFromSite.bind(this);
  }

  componentDidMount() {
    timer(0).then(() => { // They get null sometimes without this.
      let site = null;
      if (this.props.selectedConstructionSite) {
        site = this.props.selectedConstructionSite.get('id');
        this.setState({
          header: ['Rekisteri-numero', 'Vakio massa määrä', '', ''],
          fields: ['register_number', 'default_mass',
                  'button#Muokkaa#/truck/{id}',
                  'button|Poista kohteesta|0#id']
        });
      }
      this.getTrucks(site, this.props.selectedContract);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.trucks !== nextProps.trucks) return;
    if (this.props.selectedContract === nextProps.selectedContract &&
        this.props.selectedConstructionSite === nextProps.selectedConstructionSite) return;
    let site = null;
    if (nextProps.selectedConstructionSite) {
      site = nextProps.selectedConstructionSite.get('id');
      this.setState({
        header: ['Rekisteri-numero', 'Vakio massa määrä', '', ''],
        fields: ['register_number', 'default_mass',
                 'button#Muokkaa#/truck/{id}',
                 'button|Poista kohteesta|0#id']
      });
    }
    else {
      this.setState({
        header: ['Rekisteri-numero', 'Vakio massa määrä', ''],
        fields: ['register_number', 'default_mass',
                 'button#Muokkaa#/truck/{id}']
      });
    }
    this.getTrucks(site, nextProps.selectedContract);
  }

  getTrucks(site, contract) {
    this.setState({
      loading: true,
    });
    let url;
    if (site != null) url = '/trucks?site=' + site;
    else if (contract != null) url = '/trucks?contract=' + contract;
    else url = '/trucks/';
    fetch(url).then(data => {
      this.setState({
        trucks: data,
        loading: false,
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
      this.setState({
        loading: false
      });
    });
  }

  removeTruckFromSite(id) {
    const afterDeleteTrucks = this.state.trucks.filter(truck => truck.id !== id);

    let truckIDs = [];

    afterDeleteTrucks.forEach(truck => {
        truckIDs.push(truck.id);
    });

    const editedSite = {
      trucks: truckIDs
    };

    fetch('/constructionsites/' + this.props.selectedConstructionSite.get('id') + '/', 'PATCH', editedSite).then(() => {
      this.setState({
        trucks: afterDeleteTrucks
      });
      this.props.showNotice('Rekka poistettu', 'Ok')
    }).catch(error => {
      this.props.showNotice('Virhe', 'Rekan poisto epäonnistui', 'Error');
    });
  }

  render() {
    return (
    <div>
      <div className="main-client-container">
        <h1>Rekat</h1>
        <div className="center">
          <Link to="/trucks/new">
            <button type="button">Lisää rekka</button>
          </Link>
        </div>
        <List emptyText={'Ei yhtään rekkaa'}
              header={this.state.header}
              fields={this.state.fields}
              functions={[this.removeTruckFromSite]}
              data={fromJS(this.state.trucks)}/>
      </div>
    </div>
    );
  }
}


export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice, selectContract, selectContructionSite })(Trucks);
