import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showMessage } from '../../../../src/message/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { fetch } from '../utils';
import Form from "@rjsf/core";
import { permissionNames } from './Users';
import validator from '@rjsf/validator-ajv8';

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.getUser(parseInt(this.props.params.id, 10));

    this.state = {
      schema: null,
      uiSchema: {},
      formData: {},
      permissions: [],
      editingUser: null,
      loading: false,
      contracts: [],
      editingMySelf: false
    };

    this.onSubmit = this.onSubmit.bind(this)
    this.confirmUserDelete = this.confirmUserDelete.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (nextState.schema == null && nextState.editingUser != null) {
      let editingMySelf = false;

      if (nextState.editingUser.userId === nextProps.user.get('userId')) {
        editingMySelf = true
      }

      this.setForm(nextState.editingUser, nextProps.user, editingMySelf);
      this.setState({ editingMySelf: editingMySelf });
    }
  }

  setForm (editingUser, user, editingMySelf) {
    let permissionValue = [];
    let permissions = [];

    user.get('permissions').forEach(permission => {
      const name = permissionNames[permission.get('permission')];

      if (name != null) {
        permissionValue.push(permission.get('permission'));
        permissions.push(name);
      }
    });

    let contractValue = [];
    let contracts = [];

    this.props.contracts.forEach(contract => {
      contractValue.push(contract.get('id'));
      contracts.push(contract.get('name'));
    });

    let schema;
    let uiSchema;

    let userPermissions = [];

    if (editingUser.permissions != null) {
      for (let index in editingUser.permissions) {
        const permission = editingUser.permissions[index];
        const value = permission.permission;
        if (permissionValue.includes(value)) {
          userPermissions.push(value)
        }
      }
    }

    let userContractPermissions = [];

    if (editingUser.contractPermissions != null) {
      editingUser.contractPermissions.forEach(permission => {
        userContractPermissions.push(permission.contract_id)
      });
    }

    schema = {
      type: "object",
      required: ["email"],
      properties: {
        email: { type: "string", title: "Sähköposti:" },
        firstName: { type: "string", title: "Etunimi:" },
        lastName: { type: "string", title: "Sukunimi:" },
        password: { type: "string", title: "Salasana:" },
        passwordConfirm: { type: "string", title: "Vahvista salasana:" },
      }
    };

    uiSchema = {
      password: {
        "ui:widget": "password",
        "ui:help": "Jätä tyhjäksi, jos et vaihda salasanaa"
      },
      passwordConfirm: {
        "ui:widget": "password",
      },
    };

    if (!editingMySelf) {
      schema.properties = {
        ...schema.properties,
        permissions: {
          "title": "Oikeudet:",
          "type": "array",
          "items": {
            "type": "number",
            "enum": permissionValue,
            "enumNames": permissions
          },
          "uniqueItems": true
        }
      };

      uiSchema = {
        ...uiSchema,
        permissions: {
          "ui:widget": "checkboxes",
        }
      };

      schema.properties = {
        ...schema.properties,
        orderer: { type: "boolean", title: "Tilaaja" }
      };


      schema.properties = {
        ...schema.properties,
        contractPermissions: {
          "title": "Urakka kohtaset oikeudet:",
          "type": "array",
          "items": {
            "type": "number",
            "enum": contractValue,
            "enumNames": contracts
          },
        }
      };

      uiSchema = {
        ...uiSchema,
        contractPermissions: {
          "ui:options": {
            "orderable": false
          },
        }
      };
    }

    const formData = {
      email: editingUser['email'],
      firstName: editingUser['firstName'],
      lastName: editingUser['lastName'],
      permissions: userPermissions,
      orderer: editingUser['orderer'],
      contractPermissions: userContractPermissions
    };

    this.setState({
      schema: schema,
      uiSchema: uiSchema,
      formData: formData
    });
  }


  getUser (id) {
    fetch('/users/' + id).then(data => {
      this.setState({
        editingUser: data,
      });
    }).catch(error => {
      this.props.showNotice('Käyttäjää ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  async onSubmit ({ formData }) {
    if (formData.password !== formData.passwordConfirm) {
      this.props.showNotice('Salasanat eivät täsmää', 'Warning');
      return;
    }

    this.setState({ loading: true });

    const user = {
      id: this.state.editingUser['userId'],
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password || undefined,
      orderer: formData.orderer
    };

    if (!this.state.editingMySelf && this.state.editingUser.contractPermissions != null) {
      for (let index in this.state.editingUser.contractPermissions) {
        await fetch('/contractpermissions/' + this.state.editingUser.contractPermissions[index].contract_id + '/user/' + user.id, 'DELETE');
      }
    }

    fetch('/users/' + user.id, 'PATCH', user).then(async data => {
      if (!this.state.editingMySelf) {
        if (this.state.editingUser.permissions != null) {
          for (let index in this.state.editingUser.permissions) {
            const value = this.state.editingUser.permissions[index].permission;
            if (this.props.user.get('permissions').findIndex(p => p.get('permission') === value) !== -1) {
              await fetch('/permissions/' + value + '/user/' + user.id, 'DELETE');
            }
          }
        }

        for (let index in formData.permissions) {
          await fetch('/permissions/' + formData.permissions[index] + '/user/' + user.id, 'POST');
        }

        for (let index in formData.contractPermissions) {
          await fetch('/contractpermissions/' + formData.contractPermissions[index] + '/user/' + user.id, 'POST');
        }
      }

      this.props.showNotice('Käyttäjän muokkaaminen onnistui', 'Ok');
      this.setState({ redirect: true });
    }).catch(error => {
      this.setState({ loading: false });
      this.props.showNotice('Käyttäjän muokkaaminen epäonnistui', 'Error');
    });
  }

  confirmUserDelete (event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko käyttäjä?', this.deleteUser);
  }

  deleteUser () {
    fetch('/users/' + this.state.editingUser['userId'], 'DELETE').then(() => {
      this.props.showNotice('Käyttäjä poistettu', 'Ok')
      this.setState({
        redirect: true,
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Käyttäjän poisto epäonnistui', 'Error');
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/users' push />;
    if (this.state.schema == null) {
      return (
        <div className='main-client-container'>
          <h2>Ladataan tietoja...</h2>
          <div className='loader'></div>
        </div>
      );
    }
    return (
      <div className='main-client-container'>
        <h1>Muokkaa käyttäjää</h1>
        <h2>{this.state.editingUser['email']} {this.state.editingMySelf ? '(Sinä)' : null}</h2>
        <Form schema={this.state.schema} uiSchema={this.state.uiSchema} validator={validator}
          onSubmit={this.onSubmit} formData={this.state.formData}
          onChange={this.onChange} showErrorList={false}>
          {this.state.loading ?
            <div className='loader' />
            :
            <div>
              <input className='button-primary' type='submit' value='Tallenna' />
              <Link className='button-primary' to='/users'>
                <button className='button-outline'>Peruuta</button>
              </Link>
              <button className='float-right red-text button-outline' onClick={this.confirmUserDelete}
                disabled={this.state.editingMySelf}>
                Poista
              </button>
            </div>
          }
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.login.get('user'),
  contracts: state.contractSelect.get('contracts'),
}), { showNotice, showMessage, showConfirm })(UserEdit);
