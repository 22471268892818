import React from 'react';
import { connect } from 'react-redux';
import { setMessage, setMessages, clearMessages, removeMessage } from './Actions';
import { showMessage } from '../message/Actions';
import { showConfirm } from '../confirm/Actions';
import ContractSelect from '../contractSelect/ContractSelect';
import { Socket, fetch, timer, paddedNumber } from '../utils';
import './Chat.css'
import '../styles.css'


class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.socket = null;

    this.state = {
      loading: false,
      message: '',
      show: false,
      newMessageCount: 0
    };

    this.getMessages = this.getMessages.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.toggleChat = this.toggleChat.bind(this);
    this.confirmDeleteMessage = this.confirmDeleteMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
  }


  UNSAFE_componentWillMount () {
    if (localStorage['login'] == null) {
      return;
    }

    this.getMessages(this.props.selectedContract);

    if (typeof (Storage) !== 'undefined') {
      this.setState({
        message: localStorage.message
      });
    }

    if (this.socket == null) {
      this.socket = Socket('/data/organization');
      this.socket.onmessage = function (e) {
        const data = JSON.parse(e.data);

        if (data['operation'] === 'chat') {
          if (this.props.selectedContract === data.model.contractId) {
            this.props.setMessage(data.model);

            if (!this.state.show) {
              this.setState({ newMessageCount: this.state.newMessageCount + 1 });
              const audio = new Audio(require('./new_message.wav'));
              audio.play();
            }
          }
        }
      }.bind(this)
    }
  }

  componentWillUnmount () {
    if (this.socket != null) this.socket.close();
  }

  componentDidUpdate (prevProps) {
    if (this.messages != null && prevProps.messages !== this.props.messages) {
      timer(0).then(() => {
        const scrollHeight = this.messages.scrollHeight;
        const height = this.messages.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messages.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      });
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.selectedContract !== this.props.selectedContract) this.getMessages(nextProps.selectedContract);
  }

  getMessages (contract) {
    this.props.clearMessages();

    this.setState({ loading: true });

    let url = '/chat';

    if (contract != null) {
      url += '/contract/' + contract;
    }

    fetch(url).then(data => {
      this.props.setMessages(data.reverse());
    }).catch(error => {
      console.log('Error: ' + error);
    }).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  sendMessage () {
    if (this.state.message === '') return;

    if (this.socket != null) {
      const message = {
        contractId: this.props.selectedContract,
        message: this.state.message
      };
      this.socket.send(JSON.stringify(message));
      this.setState({ message: '' });

      if (typeof (Storage) !== 'undefined') {
        localStorage.message = '';
      }
    }
    else {
      const message = {
        contractId: this.props.selectedContract,
        message: this.state.message,
        userId: this.props.user
      };
      fetch('/chat', 'POST', message).then(data => {
        this.props.setMessage(data);
        this.setState({
          message: ''
        });

        if (typeof (Storage) !== 'undefined') {
          localStorage.message = '';
        }
      }).catch(error => {
        this.setState({
          message: ''
        });
        console.log('Error: ' + error);
      });
    }
  }

  changeMessage (event) {
    this.setState({
      message: event.target.value
    });

    if (typeof (Storage) !== 'undefined') {
      localStorage.message = event.target.value;
    }
  }

  toggleChat () {
    if (!this.state.show) {
      this.setState({ newMessageCount: 0 });
    }
    this.setState({ show: !this.state.show });
  }

  confirmDeleteMessage (message) {
    this.setState({ deletingMessage: message.get('id') });
    this.props.showConfirm('Poistetaan viesti: "' + message.get('message') + '"?',
      this.deleteMessage);
  }

  async deleteMessage () {
    try {
      await fetch('/chat/' + this.state.deletingMessage, 'DELETE');
      this.props.removeMessage(this.state.deletingMessage);
    } catch (error) {
      this.props.showMessage('Virhe', 'Viestin poisto epäonnistui', 'Error');
    }
  }

  render () {
    if (!this.state.show) {
      return (
        <div id='chat-button' onClick={this.toggleChat}>
          {this.state.newMessageCount > 0 ?
            <div id='chat-new-message-count'>
              {this.state.newMessageCount}
            </div>
            :
            null
          }
          <i className="fa fa-comment" />
        </div>
      );
    }

    return (
      <div onClick={this.toggleChat} className='modal'>
        <div onClick={e => e.stopPropagation()} id='chat'>
          <div id='chat-header'>
            <ContractSelect store={this.props.store} />
            {' - Keskustelu' + (this.state.networkError ? ' (Ei toiminnassa)' : '')}
            <div className='close' onClick={this.toggleChat} />
          </div>
          <div id='message-area' ref={(element) => { this.messages = element; }}>
            {this.state.loading ? <div className='loader'></div> :
              (
                this.props.messages.map(message => {
                  const date = new Date(message.get('time'));
                  const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                    + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

                  return (
                    <div className='message' key={message.get('id')}>
                      <div className='message-time'>
                        {time}
                      </div>
                      <div className='message-sender'>
                        {message.get('firstName') + ' ' + message.get('lastName') + ':'}
                      </div>
                      <div className='message-text'>
                        {message.get('message')}
                      </div>
                      {message.get('userId') === this.props.user ?
                        <div className='delete-button' onClick={this.confirmDeleteMessage.bind(this, message)} />
                        : null
                      }
                    </div>
                  );
                })
              )
            }
          </div>
          <div id='chat-footer'>
            <button id='message-send-button' onClick={this.sendMessage}>
              Lähetä
            </button>
            <div id='message-type-bar-div'>
              <input id='message-type-bar' onChange={this.changeMessage.bind(this)}
                value={this.state.message} required />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  contracts: state.contractSelect.get('contracts'),
  messages: state.chat.get('messages'),
  user: state.login.get('user') ? state.login.get('user').get('userId') : null
}), {
  setMessage, setMessages, showMessage, clearMessages,
  showConfirm, removeMessage
})(Chat);
