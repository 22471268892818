import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import List from '../../../../src/list/List';
import { showMessage } from '../../../../src/message/Actions';
import { fetch, Socket } from '../utils';
import { fromJS } from 'immutable';
import './Users.css';


export const permissionNames = {
  0: 'Manegeri',
  1: 'Tracker',
  2: 'Massa',
  3: 'Murske',
  4: 'Merkki',
  5: 'Havainto',
  6: 'Maanajo',
  7: 'Reunantäyttö',
  8: 'Tierenki'
}

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      filteredUsers: [],
      allUsers: localStorage.allUsers === 'true' || false,
      header: ['Etunimi', 'Sukunimi', 'Sähköposti', 'Oikeudet', ''],
      fields: ['firstName', 'lastName', 'email', 'permission', 'button#Muokkaa käyttäjää#/user/{userId}'],
      searchText: ''
    };

    this.toggleAllUsers = this.toggleAllUsers.bind(this)
  }

  componentDidMount () {
    if (typeof (WebSocket) !== 'undefined') {
      this.socket = Socket('/data/user')
      this.socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data['type'].includes('User')) {
          this.getUsers(this.state.allUsers);
        }
      }.bind(this)
    }

    if (this.props.user != null) {
      this.getUsers(this.state.allUsers);

      if (this.props.user.get('organizationId') === 1) {
        this.setState({
          neviaUser: true,
          header: ['Organisaatio', 'Etunimi', 'Sukunimi', 'Sähköposti', 'Oikeudet', ''],
          fields: ['organizationName', 'firstName', 'lastName', 'email', 'permission', 'button#Muokkaa käyttäjää#/user/{userId}']
        });
      }
    }
  }

  componentWillUnmount () {
    if (this.socket != null) this.socket.close();
  }

  getUsers (allusers = false) {
    this.setState({ loading: true });

    let url = '/users';

    if (this.props.user.get('organizationId') === 1 && allusers) {
      url += '/all';
    }

    fetch(url).then(data => {
      data.forEach(user => {
        user.permission = '';

        if (user.permissions != null) {
          user.permissions.forEach(permission => {
            const name = permissionNames[permission.permission];

            if (name != null) {
              user.permission += name + ', ';
            }
          });

          user.permission = user.permission.substring(0, user.permission.length - 2);
        }
      });

      this.setState({
        users: data,
        filteredUsers: data,
      });
    })
      .catch(error => {
        this.props.showMessage('Virhe', 'Palvelimelta ei saatu käyttäjiä', 'Error');
      })
      .then(error => {
        this.setState({
          loading: false
        });
      });
  }

  toggleAllUsers () {
    const value = !this.state.allUsers;
    this.setState({ allUsers: value });
    this.getUsers(value);
    localStorage.allUsers = value;
  }


  search (event) {
    const value = event.target.value.toLowerCase();

    let users = this.state.users;

    users = users.filter(user => {
      const wholeName = user.firstName + ' ' + user.lastName;

      if (wholeName.toLowerCase().includes(value)) {
        return true;
      }

      return false;
    });

    this.setState({ filteredUsers: users });
  }


  render () {
    if (this.props.user == null) return null;

    return (
      <div className='main-client-container'>
        <h1>Käyttäjät</h1>
        <div className='center'>
          <Link to='/users/new'>
            <button type='button'>Uusi käyttäjä</button>
          </Link>
          <label htmlFor='search'>
            Hae
          </label>
          <input id='search' type='text'
            onChange={this.search.bind(this)} />
          {this.state.neviaUser ?
            <label>
              Näytä kaikki käyttäjät
              <input type='checkbox'
                onClick={this.toggleAllUsers}
                defaultChecked={this.state.allUsers} />
            </label>
            :
            null
          }
        </div>
        <List many={10}
          emptyText={'Ei käyttäjiä'}
          header={this.state.header}
          fields={this.state.fields}
          data={fromJS(this.state.filteredUsers)}
          specific={this.props.user.get('userId')}
          loading={this.state.loading} />
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
}), { showMessage })(Users);
