import React from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Login from '../../../../src/login/Login.js';
import Logout from '../../../../src/login/Logout.js';

import App from './Application.js';
import Main from '../main/Main.js';
import Users from '../users/Users.js';
import UserNew from '../users/UserNew.js';
import UserEdit from '../users/UserEdit.js';
import Contracts from '../contracts/Contracts.js';
import ContractNew from '../contracts/ContractNew.js';
import ContractEdit from '../contracts/ContractEdit.js';
import ConstructionSites from '../constructionSites/ConstructionSites.js';
import ConstructionSiteNew from '../constructionSites/ConstructionSiteNew.js';
import ConstructionSiteEdit from '../constructionSites/ConstructionSiteEdit.js';
import Trucks from '../truck/Trucks.js';
import TruckNew from '../truck/TruckNew.js';
import TruckEdit from '../truck/TruckEdit.js';
import Report from '../report/Report.js';
import config from '../../settings/config';
import Reports from '../report/Reports.js';
import ReportEdit from '../report/ReportEdit.js';
import ReportNew from '../report/ReportNew.js';
import SignReport from '../report/SignReport.js';
import Templates from '../report/Templates.js';
import TemplateEdit from '../report/TemplateEdit.js';
import Organizations from '../organization/Organizations.js';
import OrganizationNew from '../organization/OrganizationNew.js';
import OrganizationEdit from '../organization/OrganizationEdit.js';
import GetFile from '../getFile/GetFile.js';

const ComponentWithParams = ({ children }) => {
  const params = useParams();

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { params });
    }
    return child;
  });

  return childrenWithProps;
}

const Root = ({ store }) => {
  const location = useLocation();

  return (
    <>
      <App store={store} location={location} />
      <Main store={store} location={location} />
      <Routes id={config.reptail ? 'reptail' : ''}>
        <Route path='/login' element={<Login store={store} location={location} />} />
        <Route path='/logout' element={<Logout store={store} location={location} />} />
        <Route path='/' element={<></>} />
        <Route path='/users' element={<Users store={store} location={location} />} />
        <Route path='/users/new' element={<UserNew store={store} location={location} />} />
        <Route 
          path='/user/:id'
          element={
            <ComponentWithParams>
              <UserEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/contracts' element={<Contracts store={store} location={location} />} />
        <Route path='/contracts/new' element={<ContractNew store={store} location={location} />} />
        <Route
          path='/contract/:id'
          element={
            <ComponentWithParams>
              <ContractEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/sites' element={<ConstructionSites store={store} location={location} />} />
        <Route path='/sites/new' element={<ConstructionSiteNew store={store} location={location} />} />
        <Route
          path='/site/:id'
          element={
            <ComponentWithParams>
              <ConstructionSiteEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/trucks' element={<Trucks store={store} location={location} />} />
        <Route path='/trucks/new' element={<TruckNew store={store} location={location} />} />
        <Route
          path='/truck/:id'
          element={
            <ComponentWithParams>
              <TruckEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/report' element={<Report store={store} location={location} />} />
        <Route path='/reports' element={<Reports store={store} location={location} />} />
        <Route
          path='/report/:id'
          element={
            <ComponentWithParams>
              <ReportEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/reports/new' element={<ReportNew store={store} location={location} />} />
        <Route
          path='/signreport/:id/:sheet/:field'
          element={
            <ComponentWithParams>
              <SignReport store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/templates' element={<Templates store={store} location={location} />} />
        <Route
          path='/template/:id'
          element={
            <ComponentWithParams>
              <TemplateEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route path='/templates/new' element={<TemplateEdit store={store} location={location} />} />
        <Route path='/organizations' element={<Organizations store={store} location={location} />} />
        <Route path='/organizations/new' element={<OrganizationNew store={store} location={location} />} />
        <Route
          path='/organization/:id'
          element={
            <ComponentWithParams>
              <OrganizationEdit store={store} location={location} />
            </ComponentWithParams>
          }
        />
        <Route
          path='/file/:file'
          element={
            <ComponentWithParams>
              <GetFile store={store} location={location} />
            </ComponentWithParams>
          }
        />
      </Routes>
    </>
  );
}
export default Root;