let config = {
	cors: 'socket.nevia-app.com:1212',
	api: 'i0noysekw1.execute-api.eu-west-1.amazonaws.com/v1',
	fileApi: 'socket.nevia-app.com:9000',
	//api: '52dfffj6il.execute-api.eu-west-1.amazonaws.com/Teststage',
	socket: 'socket.nevia-app.com:57679',
	//socket: 'test.ec2.nevia-app.com:57679',
	road: 'socket.nevia-app.com:1212/https://avoinapi.vaylapilvi.fi/viitekehysmuunnin/muunna',
	test: false,
	reptail: false
}

export default config;
