import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import CoatingPlantSelect from './CoatingPlantSelect'
import { showNotice } from '../../../../src/notice/Actions';
import { selectContract } from '../../../../src/contractSelect/Actions';
import { addContructionSite } from '../../../../src/constructionSiteSelect/Actions';
import { fetch } from '../utils';
import config from '../../settings/config';
import './ConstructionSites.css';
import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';

class ConstructionSiteNew extends React.Component {
  constructor(props) {
    super(props);

    const MassPermission = this.props.permissions != null ?
      this.props.permissions.find(permission => permission.get('permission') === 2) != null
      :
      false;

    this.MACADAM = config.reptail || !MassPermission;

    this.ROADHAND_PERMISSION = this.props.permissions != null ?
      this.props.permissions.find(permission => permission.get('permission') === 8) != null
      :
      false;

    if (!this.MACADAM) {
      this.getMassTypes();
    }

    this.getTrucks();
    this.getContracts();

    this.schema = null;
    this.formData = {};

    this.state = {
      massTypes: [],
      allTrucks: [],
      contracts: [],
      loadingTrucks: true,
      coatingPlants: [{}]
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.setCoatingPlant = this.setCoatingPlant.bind(this);
    this.addNewCoatingPlantRow = this.addNewCoatingPlantRow.bind(this);
  }


  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (this.schema === null && (this.MACADAM || nextState.massTypes.length !== 0) &&
      nextState.contracts.length !== 0 && !nextState.loadingTrucks) {
      this.setForm(nextProps.site, nextState.massTypes, nextState.contracts);
    }
  }


  setForm (editingSite, massTypesAll, contractsAll) {
    let contractsID = [];
    let contracts = [];

    contractsAll.forEach(contract => {
      contractsID.push(contract['id']);
      contracts.push(contract['name']);
    });

    let massTypes = [];

    massTypesAll.forEach(type => {
      massTypes.push(type['name']);
    });

    this.schema = {
      type: "object",
      required: ["contract", "name"],
      properties: {
        contract: {
          type: "number", enum: contractsID, enumNames: contracts,
          title: "Urakka:"
        },
        name: { type: "string", title: "Nimi:" },
        customId: { type: "string", title: "Kohdetunnus:" },
        road: { type: "integer", title: "Tie:" },
        startPart: { type: "integer", title: "Aloitus tieosa:" },
        startPole: { type: "integer", title: "Aloitus paalu:" },
        endPart: { type: "integer", title: "Lopetus tieosa:" },
        endPole: { type: "integer", title: "Lopetus paalu:" }
      }
    };

    if (!this.MACADAM) {
      this.schema.required.concat(["massType", "operationType", "flatness"]);
      this.schema.properties.massType = { type: "string", enum: massTypes, enumNames: massTypes, title: "Massatyyppi:" };
      this.schema.properties.operationType = { type: "string", title: "Toimenpidetyyppi:" };
      this.schema.properties.flatness = { type: "string", title: "Kg / m²:" };
    }

    if (this.ROADHAND_PERMISSION) {
      this.schema.properties.lane = { type: "number", title: "Kaista:" };
      this.schema.properties.length = { type: "number", title: "Pituus:" };
      this.schema.properties.quality = { type: "string", title: "Laatuluokka:" };
      this.schema.properties.kvl = { type: "number", title: "KVL:" };
      this.schema.properties.comments = { type: "string", title: "Kommentit:" };
    }

    this.uiSchema = {
      registerNumber: {
        "ui:placeholder": "ABC-123",
        "ui:autofocus": true
      },
      road: {
        "ui:widget": "updown",
      }
    };

    this.formData = {
      contract: this.props.selectedContract,
    };
  }

  getMassTypes () {
    fetch('/masses/types/').then(data => {
      this.setState({
        massTypes: data,
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }

  getTrucks () {
    fetch('/trucks/').then(data => {
      this.setState({
        allTrucks: data,
        loadingTrucks: false
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
      this.setState({
        loadingTrucks: false
      });
    });
  }

  getContracts () {
    fetch('/contracts/').then(data => {
      this.setState({
        contracts: data,
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }

  onSubmit ({ formData }) {
    let site = {
      name: formData.name,
      road_number: formData.road,
      contract_id: formData.contract,
      mass_type: formData.massType,
      operation_type: formData.operationType,
      flatness: formData.flatness,
      trucks: formData.trucks,
      start_part: formData.startPart,
      end_part: formData.endPart,
      start_distance: formData.startPole,
      end_distance: formData.endPole,
      organization_id: this.props.organizationId,
    };

    if (this.ROADHAND_PERMISSION) {
      site.custom_id = formData.customId;
      site.lane = formData.lane;
      site.length = formData.length;
      site.quality = formData.quality;
      site.kvl = formData.kvl;
      site.comments = formData.comments;
    }

    fetch('/constructionsites/', 'POST', site).then(async data => {
      for (let index in this.state.coatingPlants) {
        const plant = this.state.coatingPlants[index];

        if (plant.latidute == null) {
          continue;
        }

        const url = !this.MACADAM ? '/coatingplant' : '/pit';
        await fetch(url, 'POST', plant);
      }

      this.props.showNotice('Kohde lisätty', 'Ok');
      this.props.addContructionSite(data);
      this.setState({ redirect: true });
    }).catch(error => {
      this.props.showNotice('Kohteen lisäys epäonnistui', 'Error');
    });
  }

  setCoatingPlant (latidute, longitude, name, index) {
    let coatingPlants = this.state.coatingPlants;

    coatingPlants[index] = {
      latidute: latidute,
      longitude: longitude,
      name: name || null
    };

    this.setState({ coatingPlants: coatingPlants });
  }

  addNewCoatingPlantRow () {
    this.setState({
      coatingPlants: this.state.coatingPlants.concat({}),
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/sites' push />;

    if (this.schema == null) {
      return (
        <div className='main-client-container'>
          <div className='loader'></div>
        </div>
      );
    }

    return (
      <div className='main-client-container'>
        <h1>Lisää kohde</h1>
        <Form schema={this.schema} uiSchema={this.uiSchema} validator={validator}
          onSubmit={this.onSubmit} formData={this.formData}
          onChange={({ formData }) => {
            this.formData = formData;
            this.props.selectContract(this.formData.contract);
          }}
          showErrorList={false}>
          {
            this.state.coatingPlants.map((plant, index) => (
              <CoatingPlantSelect key={index}
                setCoatingPlant={this.setCoatingPlant}
                macadam={this.MACADAM}
                index={index} />
            ))
          }
          <button className='button-outline' onClick={this.addNewCoatingPlantRow}>
            Lisää uusi rivi
          </button>
          <br />
          <input className='button-primary' type='submit' value='Luo kohde' />
          <Link className='button-primary' to='/sites/'>
            <button className='button-outline'>Peruuta</button>
          </Link>
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
  organizationId: state.login.get('user') == null ? null : state.login.get('user').get('organizationId'),
  permissions: state.login.get('user') == null ? null : state.login.get('user').get('permissions'),
  selectedContract: state.contractSelect.get('selectedContract'),
}), { showNotice, selectContract, addContructionSite })(ConstructionSiteNew);
