import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMessage } from '../../../../src/message/Actions';
import List from '../../../../src/list/List';
import { fetch } from '../utils';
import { fromJS } from 'immutable';


class Organizations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      organizations: [],
      filteredOrganizations: [],
      header: ['Nimi', 'Y-tunnus', 'Aktiivinen', ''],
      fields: ['name', 'company_code', 'active', 'button#Muokkaa#/organization/{id}'],
      searchText: ''
    };
  }

  componentDidMount() {
    this.getOrganizations();
  }

  async getOrganizations() {
    this.setState({ loading: true });

    try {
      const data = await fetch('/organizations');

      this.setState({
        organizations: data,
        filteredOrganizations: data
      });
    } catch(error) {
      this.props.showMessage('Virhe', 'Palvelimelta ei saatu organisaatioita', 'Error');
    }

    this.setState({ loading: false });
  }

  search(event) {
    const value = event.target.value.toLowerCase();

    let organizations = this.state.organizations;

    organizations = organizations.filter(organization => {
      if (organization.name.toLowerCase().includes(value)) {
        return true;
      }

      return false;
    });

    this.setState({ filteredOrganizations: organizations });
  }


  render() {
    if (this.props.user == null) return null;

    return (
      <div className='main-client-container'>
        <h1>Organisaatiot</h1>
        <div className='center'>
          <Link to='/organizations/new'>
            <button type='button'>
              Uusi Organisaatio
            </button>
          </Link>
          <label htmlFor='search'>
            Hae
          </label>
          <input id='search' type='text'
                onChange={this.search.bind(this)} />
        </div>
        <List many={10}
              emptyText={'Tyhjää'}
              header={this.state.header}
              fields={this.state.fields}
              data={fromJS(this.state.filteredOrganizations)}
              loading={this.state.loading} />
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
}), { showMessage })(Organizations);
