import React from "react";
import { createRoot } from "react-dom/client";
import Root from "./components/application/Root";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";
import { BrowserRouter } from "react-router-dom";
import LoginReducer from '../../src/login/Reducer.js';
import MenuReducer from '../../src/menu/Reducer.js';
import MessageReducer from '../../src/message/Reducer.js';
import NoticeReducer from '../../src/notice/Reducer.js';
import ConfirmReducer from '../../src/confirm/Reducer.js';
import ContractSelectReducer from '../../src/contractSelect/Reducer.js';
import ConstructionSiteSelectReducer from '../../src/constructionSiteSelect/Reducer.js';
import TimeRangeReducer from '../../src/timeRange/Reducer.js';
import ChatReducer from '../../src/chat/Reducer.js';
import "./index.css";
import { register } from './serviceWorkerRegistration';

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    chat: ChatReducer,
    timeRange: TimeRangeReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <Root store={store} />
    </BrowserRouter>
  </Provider>
);

register();
