import React from "react";
import { connect } from "react-redux";
import { showMessage } from "../../../../src/message/Actions";
import { showNotice } from "../../../../src/notice/Actions";
import { currentTime, fetch, getPropertyFromJson } from "../utils";
import { Link, Navigate } from "react-router-dom";
import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';

class ReportNew extends React.Component {

  constructor(props) {
    super(props);

    this.getTemplates();

    this.state = {
      redirect: false,
      redirectURL: null,
      templateId: null,
      template: null,
      allTemplates: [],
      loadingTemplate: false,
      schema: null,
      formData: null,
      uiSchema: null,
    };
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (this.state.allTemplates.length === 0 && nextState.allTemplates.length > 0) {
      this.setState({
        templateId: nextState.allTemplates[0].id
      });
    }
    if (nextState.templateId !== this.state.templateId) {
      this.getTemplate(nextState.templateId);
    }
    if (nextState.template != null && nextState.template !== this.state.template) {
      this.setForm(nextState.template);
    }
  }

  getTemplates () {
    fetch('/template').then(data => {
      this.setState({
        allTemplates: data,
        loadingTemplate: false
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
      this.setState({
        loadingTemplate: false
      });
    });
  }

  getTemplate (id) {
    fetch('/template/' + id).then(data => {
      this.setState({
        template: data,
        loadingTemplate: true
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
      this.setState({
        loadingTemplate: false
      });
    });
  }

  setForm (editingForm) {
    let schema = {
      type: "object",
      properties: {}
    };

    let formData = {};

    let uiSchema = {};

    Object.keys(editingForm.sheets).forEach(sheet => {
      let sheet_name = function (sheet) {
        if (sheet.indexOf("_") > -1) {
          let name = sheet.split("_")[1];
          if (name === "blank")
            return "";
          else
            return name
        } else {
          return "";
        }
      };

      if (sheet.indexOf("Object") === 0) { // skip custom object sheet
        return;
      } else if (sheet.indexOf("single") > -1) { // add sheet which only has one row
        let tableSchema = { title: sheet_name(sheet), type: "object", properties: {} };
        let tableData = {};
        let tableUi = {};
        Object.keys(editingForm.sheets[sheet]).forEach(field => {
          let prop = getPropertyFromJson(editingForm, sheet, field);
          tableSchema.properties[field] = prop.schema;
          if (prop.ui && Object.keys(prop.ui).length > 0)
            tableUi[field] = prop.ui;
          if (prop.data) {
            if (Array.isArray(prop.data))
              tableData[field] = prop.data[0];
            else
              tableData[field] = prop.data;
          }
        });
        schema.properties[sheet] = tableSchema;
        uiSchema[sheet] = tableUi;
        formData[sheet] = tableData;
      } else {
        // add sheet which has multiple rows
        let tableSchema = {
          title: sheet_name(sheet),
          type: "array",
          items: {
            type: "object",
            properties: {}
          }
        };
        let tableData = [{}];
        let tableUi = {
          "ui:options": {
            orderable: false
          },
          "items": {}
        };
        Object.keys(editingForm.sheets[sheet]).forEach(field => {
          let prop = getPropertyFromJson(editingForm, sheet, field);
          tableSchema.items.properties[field] = prop.schema;
          if (prop.ui && Object.keys(prop.ui).length > 0)
            tableUi["items"][field] = prop.ui;
          if (prop.data) {
            prop.data.forEach((value, index) => {
              if (tableData.length - 1 < index) {
                tableData.push({});
              }
              tableData[index][field] = value;

            });
          }
        });
        schema.properties[sheet] = tableSchema;
        formData[sheet] = tableData;
        uiSchema[sheet] = tableUi;
      }
    });

    this.setState({
      schema: schema,
      formData: formData,
      uiSchema: uiSchema,
      loadingTemplate: false
    });

  }

  onSubmit (formData) {
    // site cannot be null
    if (!this.props.selectedConstructionSite) {
      this.props.showNotice('you did not choose site', 'Error');
      return;
    }

    const report = {
      site_id: this.props.selectedConstructionSite.get('id'),
      template_id: this.state.templateId,
      last_modify: currentTime(),
      sheets: {}
    };
    for (let key in formData) {
      report.sheets[key] = {};
      if (!Array.isArray(formData[key])) { // single value for one field
        for (let field in formData[key]) {
          if (formData[key][field] != null) {
            report.sheets[key][field] = {};
            if (typeof formData[key][field] === "object") { // custom object
              report.sheets[key][field]["value"] = [];
              Object.keys(formData[key][field]).forEach(subValue => {
                if (typeof formData[key][field][subValue] === "undefined")
                  report.sheets[key][field]["value"].push(null);
                else
                  report.sheets[key][field]["value"].push(formData[key][field][subValue]);
              });
            } else {
              if (typeof formData[key][field] !== "undefined")
                report.sheets[key][field]["value"] = formData[key][field];
            }
          }
        }
      } else { // multiple values for one field
        formData[key].forEach(column => {
          for (let field in column) {
            if (!report.sheets[key][field]) {
              report.sheets[key][field] = {};
              report.sheets[key][field]["value"] = [];
            }
            if (typeof column[field] === "undefined")
              report.sheets[key][field]["value"].push(null);
            else
              report.sheets[key][field]["value"].push(column[field]);
          }
        })
      }
    }

    fetch('/report/', 'POST', report).then((data) => {
      if (data) {
        this.setState({
          redirect: true,
          redirectURL: "/report/" + data.id
        });
      } else {
        this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
      }
    }).catch(error => {
      this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to={this.state.redirectURL} push />;
    if (this.state.allTemplates == null) return null;

    let loadForm;
    if (this.state.loadingTemplate) {
      loadForm = <div className="main-client-container">
        <h2>Ladataan tietoja...</h2>
        <div className="loader"></div>
      </div>
    }
    else if (!this.state.loadingTemplate && this.state.schema != null) {
      loadForm = <Form schema={this.state.schema} uiSchema={this.state.uiSchema} validator={validator}
        onSubmit={(e) => this.onSubmit(e.formData)} formData={this.state.formData}
        showErrorList={false}>
        <div>
          <button className="button-primary" type="submit">Lisää raportti</button>
          <Link to="/reports">
            <button className="button-outline">Peruuta</button>
          </Link>
        </div>
      </Form>
    }

    return (
      <div className="main-client-container">
        <h1>Uusi raportti</h1>
        <div>
          <div className="row">
            <div className="column">
              <label>Template</label>
              <select value={this.state.templateId} onChange={(e) => this.setState({ templateId: e.target.value })}>
                {this.state.allTemplates.map(temp =>
                  <option value={temp.id}>{temp.name}</option>)}
              </select>
            </div>
          </div>
          {loadForm}
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice, showMessage })(ReportNew);