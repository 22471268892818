import React from 'react';
import { connect } from 'react-redux';
import Weather from '../../../../src/weather/Weather';
import { logout } from '../../../../src/login/Actions';
import TopContractAndSiteSelect from '../../../../src/topContractAndSiteSelect/TopContractAndSiteSelect'
import Chat from '../../../../src/chat/Chat'
import { Navigate } from 'react-router-dom';
import NoSleep from 'nosleep.js';
import { integerValue } from '../utils'
import config from '../../settings/config';
import './Main.css';
const noSleep = new NoSleep();

let TEST = config.test ? 'test.' : '';
let REPTAIL = config.reptail;

const View = props => {
  if (!props.show) return null;

  return (
    <div id='app-container'>
      {
        props.buttons.map(application => {
          return (
            <div className='app-button' key={application.name}
              onClick={application.action}>
              {application.icon != null ?
                <img src={'images/' + application.icon} alt='' />
                :
                <i className={application.i_icon} />
              }
              <p>{application.name}</p>
            </div>
          );
        })
      }
    </div>
  );
}

const ManagerView = props => {
  if (!props.show) return null;

  return (
    <div id='app-container'>
      {props.orderer ? <h2>Valitse ohjelma</h2> : <h2>Manageri</h2>}
      {
        props.buttons.map(application => (
          <div className='app-button' key={application.name}
            onClick={application.action}>
            <img src={'images/' + application.icon} alt='' />
            <p>{application.name}</p>
          </div>
        ))
      }
    </div>
  );
}

const GuideView = props => {
  if (!props.show) return null;

  return (
    <div id='app-container'>
      <h2>Ohjeet</h2>
      {
        props.guides.map(guide => (
          <div className='text-button' key={guide.name}
            onClick={() => {
              window.open(guide.url)
            }}>
            <p>{guide.name}</p>
          </div>
        ))
      }
    </div>
  );
}


class Main extends React.Component {

  constructor(props) {
    super(props);

    this.iframe = {};
    this.managerIframe = {};
    this.watchID = null;
    this.xDown = null;
    this.yDown = null;

    this.state = {
      applications: [],
      managerApplications: [],
      openedApplications: [],
      openedManagerApplications: [],
      showMainView: true,
      showManagerView: false,
      showGuideView: false,
      app: null,
      latitude: null,
      longitude: null,
      guides: []
    };

    this.setLocation = this.setLocation.bind(this);
    this.locationError = this.locationError.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.toggleMainView = this.toggleMainView.bind(this);
    this.toggleManagerView = this.toggleManagerView.bind(this);
    this.toggleGuideView = this.toggleGuideView.bind(this);
    this.clearApp = this.clearApp.bind(this);
    this.clearManagerApp = this.clearManagerApp.bind(this);
    this.message = this.message.bind(this);
    this.clearGoTo = this.clearGoTo.bind(this);
    this.setManagerPath = this.setManagerPath.bind(this);
  }
  componentDidUpdate (oldProps, oldState) {
    if (this.props.user != null && oldProps.user !== this.props.user) {
      // Enable wake lock.
      // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
      document.addEventListener('click', function enableNoSleep () {
        document.removeEventListener('click', enableNoSleep, false);
        noSleep.enable();
      }, false);
      this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError,
        { enableHighAccuracy: true });
      document.addEventListener('touchstart', this.handleTouchStart, false);
      document.addEventListener('touchmove', this.handleTouchMove, false);

      this.orderer = this.props.user.get('orderer');

      /**
       * 0 = Manager
       * 1 = Tracker
       * 2 = Mass
       * 3 = Macadam
       * 4 = NeviSign
       * 5 = NeviObs
       * 6 = Earthwork
       * 7 = EdgeFilling
       * 8 = RoadHand
      */
      let applications = [];
      let managerApplications = [];
      let guides = [];

      if (this.props.user.get('permissions').find(p => p.get('permission') === 2) != null) {
        let url = 'nevipaver.nevia-app.com';

        if (REPTAIL) {
          url = 'nidus.app'
        }

        applications.push(
          {
            name: 'Massa',
            icon: 'paver.gif',
            url: 'https://' + TEST + 'mass.' + url,
            action: this.setApp.bind(this, 'Massa')
          }
        );
        applications.push(
          {
            name: 'Asema',
            icon: 'coating_plant.gif',
            url: 'https://' + TEST + 'load.' + url,
            action: this.setApp.bind(this, 'Asema')
          }
        );
        managerApplications.push(
          {
            name: 'Massa',
            icon: 'paver.gif',
            url: 'https://' + TEST + 'manager.' + url,
            action: this.setManagerApp.bind(this, 'Massa')
          }
        );

        guides.push(
          {
            name: 'Nevipaver ohje',
            url: 'http://files.nevia-app.com/nevipaver_guide.pdf',
          }
        );
      }

      if (this.props.user.get('permissions').find(p => p.get('permission') === 3) != null) {

        let url = 'nevimacadam.nevia-app.com';

        if (REPTAIL) {
          url = 'nidus.app'
        }

        applications.push(
          {
            name: 'Murske',
            icon: 'macadam.gif',
            url: 'https://' + TEST + 'macadam.' + url,
            action: this.setApp.bind(this, 'Murske')
          }
        );

        managerApplications.push(
          {
            name: 'Murske',
            icon: 'macadam.gif',
            url: 'https://' + TEST + 'manager.' + url,
            action: this.setManagerApp.bind(this, 'Murske')
          }
        );
      }

      if (this.props.user.get('permissions').find(p => p.get('permission') === 4) != null) {
        applications.push(
          {
            name: 'Merkki',
            icon: 'sign.gif',
            url: 'https://' + TEST + 'nevisign.nevia-app.com',
            action: this.setApp.bind(this, 'Merkki')
          }
        );
      }

      if (this.props.user.get('permissions').find(p => p.get('permission') === 5) != null) {
        applications.push(
          {
            name: 'NeviObs',
            icon: 'obs.gif',
            url: 'https://' + TEST + 'neviobs.nevia-app.com',
            action: this.setApp.bind(this, 'NeviObs')
          }
        );
        guides.push(
          {
            name: 'NeviObs ohje',
            url: 'http://files.nevia-app.com/neviobs_ohje.pdf',
          }
        );
      }

      if (this.props.user.get('permissions').find(p => p.get('permission') === 6) != null) {
        applications.push(
          {
            name: 'Maanajo',
            icon: 'earthwork.gif',
            url: 'https://' + TEST + 'earthwork.nevia-app.com',
            action: this.setApp.bind(this, 'Maanajo')
          }
        );
      }

      if (this.props.user.get('permissions').find(p => p.get('permission') === 7) != null) {
        applications.push(
          {
            name: 'Reunantäyttö',
            icon: 'edgefilling.gif',
            url: 'https://' + TEST + 'edgefilling.nevia-app.com',
            action: this.setApp.bind(this, 'Reunantäyttö')
          }
        );
      }

      if (this.props.user.get('permissions').find(p => p.get('permission') === 8) != null) {
        applications.push(
          {
            name: 'Tierenki',
            icon: 'roadhand.png',
            url: 'https://' + TEST + 'roadhand.nevia-app.com',
            action: this.setApp.bind(this, 'Tierenki')
          }
        );
      }

      if (!this.orderer) {
        if (this.props.user.get('permissions').find(p =>
          p.get('permission') === 2 ||
          p.get('permission') === 3 ||
          p.get('permission') === 4 ||
          p.get('permission') === 6) != null) {
          managerApplications.push(
            {
              name: 'Raportit',
              icon: 'report.gif',
              action: this.goTo.bind(this, '/report')
            }
          );
          managerApplications.push(
            {
              name: 'Urakat',
              icon: 'contract.gif',
              action: this.goTo.bind(this, '/contracts')
            }
          );
          managerApplications.push(
            {
              name: 'Kohteet',
              icon: 'site.gif',
              action: this.goTo.bind(this, '/sites')
            }
          );
        }

        if (this.props.user.get('permissions').find(p =>
          p.get('permission') === 2 ||
          p.get('permission') === 3 ||
          p.get('permission') === 6) != null) {
          managerApplications.push(
            {
              name: 'Rekat',
              icon: 'truck.gif',
              action: this.goTo.bind(this, '/trucks')
            }
          );
        }

        if (this.props.user.get('permissions').find(p => p.get('permission') === 0) != null) {
          applications.unshift(
            {
              name: 'Manageri',
              icon: 'manager.gif',
              action: this.toggleManagerView
            }
          );

          managerApplications.push(
            {
              name: 'Käyttäjät',
              icon: 'users.png',
              action: this.goTo.bind(this, '/users')
            }
          );

          if (this.props.user.get('organizationId') === 1) {
            managerApplications.push(
              {
                name: 'Organisaatiot',
                icon: 'organization.gif',
                action: this.goTo.bind(this, '/organizations')
              }
            );
          }
        }

        if (this.props.user.get('permissions').find(p => p.get('permission') === 1) != null) {
          managerApplications.push(
            {
              name: 'Tracker',
              icon: 'tracker.gif',
              url: 'https://' + TEST + 'nevitracker.nevia-app.com',
              action: this.setApp.bind(this, 'Tracker')
            }
          );
        }

        applications.push(
          {
            name: 'Ohjeet',
            i_icon: 'fa fa-question-circle',
            action: this.toggleGuideView
          }
        );

        if (this.props.user.get('organizationId') === 2) {
          guides.push(
            {
              name: 'Turvallisuus ohje',
              url: 'http://files.nevia-app.com/turvallisuusohje_grk.pdf'
            }
          );
        }
      }

      // REPTAIL GUIDES
      if (REPTAIL) {
        guides.push(
          {
            name: 'Nidus käyttöohje',
            url: 'http://files.nevia-app.com/reptail_guide.pdf'
          }
        );

        guides.push(
          {
            name: 'Nidus pikakäyttöohje',
            url: 'http://files.nevia-app.com/reptail_quick_guide.pdf'
          }
        );
      }

      this.setState({
        applications: applications,
        managerApplications: managerApplications,
        guides: guides,
        showMainView: true,
        showManagerView: false,
        showGuideView: false,
      });

      if (this.orderer) {
        this.setState({
          showMainView: false,
          showManagerView: true
        });
      }

      window.addEventListener('message', this.message);
    }
  }

  componentWillUnmount () {
    noSleep.disable();
    window.removeEventListener('message', this.message);
    navigator.geolocation.clearWatch(this.watchID);
  }

  setLocation (position) {
    console.log(position);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    });
  }

  locationError (err) {
    this.setState({ locationError: true });
  }

  getTouches (event) {
    return event.touches;
  }

  handleTouchStart (event) {
    const firstTouch = this.getTouches(event)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  handleTouchMove (event) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const xUp = event.touches[0].clientX;
    const yUp = event.touches[0].clientY

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff <= -8) {
        this.state.back ? this.state.back() : this.setManagerPath();
      }
    }

    this.xDown = null;
    this.yDown = null;
  }

  toggleMainView () {
    this.setState({
      showMainView: !this.state.showMainView,
      showManagerView: false,
      showGuideView: false,
      back: null
    });
  }

  toggleManagerView () {
    this.setState({
      showMainView: false,
      showManagerView: !this.state.showManagerView,
      back: this.toggleMainView
    });
  }

  toggleGuideView () {
    this.setState({
      showMainView: false,
      showGuideView: !this.state.showGuideView,
      back: this.toggleMainView
    });
  }

  async sendPostMessage(iframe, iframeName) {
    // Sleep 200ms. This is for Chrome.
    // There should be better way of doing this.
    await new Promise(r => setTimeout(r, 200));
    iframe[iframeName].contentWindow.postMessage({
      type: 'storage',
      login: localStorage['login'],
      contract: localStorage['contract'],
      site: localStorage['site']
    }, "*");
  }

  setApp (name) {
    const openAlready = this.state.openedApplications.find(app => app.name === name) != null;

    if (!openAlready) {
      let openedApps = this.state.openedApplications;
      let newApp = this.state.applications.find(app => app.name === name);

      const lastUrl = localStorage['lastUrl-' + this.props.user.get('userId') + '-' +
        newApp.url.substring(newApp.url.indexOf('/') + 2, newApp.url.indexOf('.'))];

      if (lastUrl) {
        newApp.lastUrl = lastUrl;
      }

      openedApps.push(newApp);
      this.setState({
        openedApplications: openedApps
      }, () => {
        this.setState({
          app: name,
          back: this.clearApp
        });
      });
    }
    else {
      this.iframe[name].contentWindow.postMessage({
        type: 'contract',
        contract: integerValue(localStorage['contract'], null)
      }
        , "*");
      this.iframe[name].contentWindow.postMessage({
        type: 'site',
        site: integerValue(localStorage['site'], null)
      }
        , "*");
      this.setState({
        app: name,
        back: this.clearApp
      });
    }
  }

  setManagerApp (name) {
    const openAlready = this.state.openedManagerApplications.find(app => app.name === name) != null;
    if (!openAlready) {
      let openedApps = this.state.openedManagerApplications;
      const newApp = this.state.managerApplications.find(app => app.name === name);
      openedApps.push(newApp);
      this.setState({
        openedManagerApplications: openedApps
      }, () => {
        this.setState({
          managerApp: name,
          back: this.clearManagerApp
        });
      });
    }
    else {
      this.managerIframe[name].contentWindow.postMessage({
        type: 'storage',
        login: localStorage['login'],
        contract: localStorage['contract'],
        site: localStorage['site']
      }
        , "*");
      this.setState({
        managerApp: name,
        back: this.clearManagerApp
      });
    }
  }

  message (e) {
    if (e.data.type === 'logout') {
      this.clearManagerApp();
      this.clearApp();
      localStorage.removeItem('login');
      this.props.logout();
    }
    else if (e.data.type === 'contract') {
      localStorage['contract'] = e.data.contract;
    }
    else if (e.data.type === 'site') {
      localStorage['site'] = e.data.site;
    }
    else if (e.data.type === 'urlChanged') {
      const url = e.data.url;
      const appName = url.substring(url.indexOf('/') + 2, url.indexOf('.'));
      const path = url.substring(8 + appName.length, url.length);
      const endPart = path.substring(path.indexOf('/'), path.length);
      localStorage['lastUrl-' + this.props.user.get('userId') + '-' + appName] = endPart;
    }
  }

  clearApp () {
    this.setState({
      app: null,
      back: null,
    });
  }

  clearManagerApp () {
    this.setState({
      managerApp: null,
      back: this.toggleMainView
    });
  }

  goTo (path) {
    this.setState({
      changeUrl: path,
      openedApplications: []
    }, () => {
      this.setState({
        changeUrl: null
      });
    });
  }

  clearGoTo () {
    this.setState({
      changeUrl: '/'
    }, () => {
      this.setState({
        changeUrl: null
      });
    });
  }

  setManagerPath () {
    this.setState({
      showMainView: false,
      showManagerView: true,
      back: this.toggleMainView
    });
    this.clearGoTo();
  }

  render () {
    if (this.state.changeUrl != null) {
      return <Navigate to={this.state.changeUrl} push />;
    }
    if (this.props.location.pathname === '/login' || this.props.location.pathname === '/logout') {
      return null;
    }

    return (
      <div>
        <TopContractAndSiteSelect store={this.props.store} />
        {
          this.state.openedApplications.map(app => {
            if (app.url == null) return null;
            return (
              <iframe
                key={app.name}
                className={'iframe' + (this.state.app === app.name ? ' show' : '')}
                ref={element => this.iframe[app.name] = element}
                src={app.url + (app.lastUrl ? app.lastUrl : '')}
                allow='geolocation'
                title={app.name}
                onLoad={() => this.sendPostMessage(this.iframe, app.name)}
              />
            );
          })
        }
        {
          this.state.openedManagerApplications.map(app => {
            if (app.url == null) return null;
            return (
              <iframe
                key={app.name}
                className={'iframe' + (this.state.managerApp === app.name ? ' show' : '')}
                ref={element => this.managerIframe[app.name] = element}
                src={app.url}
                allow='geolocation'
                title={'Manager - ' + app.name}
                onLoad={() => this.sendPostMessage(this.managerIframe, app.name)}
              />
            );
          })
        }

        {this.props.location.pathname !== '/' || this.state.app != null || this.state.managerApp != null ? null :
          <div>
            <View show={this.state.showMainView} buttons={this.state.applications} />
            <ManagerView show={this.state.showManagerView} buttons={this.state.managerApplications}
              toggleManagerView={this.toggleManagerView}
              orderer={this.orderer} />
            <GuideView show={this.state.showGuideView} guides={this.state.guides} />
          </div>
        }
        <div id={'navigate-left'} className={(this.state.back != null ? '' : ' disabled')}
          onClick={this.props.location.pathname !== '/' ? this.setManagerPath : this.state.back}>
          <i className='fa fa-caret-left' />
        </div>
        <div id='top-corner-container'>
          {this.orderer ? null : <Chat store={this.props.store} />}
          <Weather latitude={this.state.latitude} longitude={this.state.longitude} />
        </div>
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user')
}), { logout })(Main);
