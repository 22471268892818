import React from "react";
import { currentTime, fetch, getPropertyFromJson } from "../utils";
import { connect } from "react-redux";
import { showMessage } from "../../../../src/message/Actions";
import { showNotice } from "../../../../src/notice/Actions";
import { showConfirm } from "../../../../src/confirm/Actions";
import { Link, Navigate } from 'react-router-dom';
import Form from "@rjsf/core";
import './Report.css';
import validator from '@rjsf/validator-ajv8';

class ReportEdit extends React.Component {

  constructor(props) {
    super(props);

    this.getReport(parseInt(this.props.params.id, 10));
    this.getContracts();

    this.state = {
      schema: null,
      formData: {},
      uiSchema: null,
      redirect: false,
      reload: false,
      editReport: null,
      sites: {},
      sitesID: {},
      contracts: [],
      contractsID: []
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.delete = this.delete.bind(this);
    this.sendCopies = this.sendCopies.bind(this);
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (this.state.schema === null && this.state.editReport != null && this.state.contracts.length > 0 &&
      Object.keys(nextState.sites).length === this.state.contracts.length) {
      this.setForm(this.state.editReport, nextState.sitesID, nextState.sites);
    }
  }

  setForm (editingReport, sitesID, sites) {
    let uiSchema = {};

    let formData = {
      contract: editingReport.constructionSite['contractId'],
      constructionSite: editingReport.site_id,
    };

    let schema = {
      type: "object",
      required: ["contract", "constructionSite"],
      properties: {
        contract: {
          type: "number", enum: this.state.contractsID, enumNames: this.state.contracts,
          title: "Urakka:", default: this.state.contractsID[0]
        },
        constructionSite: {
          type: "number", enum: sitesID[formData.contract],
          enumNames: sites[formData.contract], title: "Kohde:", default: editingReport.site_id
        },
      }
    };

    Object.keys(editingReport.sheets).forEach(sheet => {
      let sheet_name = function (sheet) {
        if (sheet.indexOf("_") > -1) {
          let name = sheet.split("_")[1];
          if (name === "blank")
            return "";
          else
            return name
        } else {
          return "";
        }
      };

      if (sheet.indexOf("Object") === 0) { // skip custom object sheet
        return;
      } else if (sheet.indexOf("single") > -1) { // add sheet which only has one row
        let tableSchema = { title: sheet_name(sheet), type: "object", properties: {} };
        let tableData = {};
        let tableUi = {};
        Object.keys(editingReport.sheets[sheet]).forEach(field => {
          let prop = getPropertyFromJson(editingReport, sheet, field);
          tableSchema.properties[field] = prop.schema;
          if (prop.ui && Object.keys(prop.ui).length > 0)
            tableUi[field] = prop.ui;
          if (prop.data) {
            if (Array.isArray(prop.data))
              tableData[field] = prop.data[0];
            else
              tableData[field] = prop.data;
          }
        });
        schema.properties[sheet] = tableSchema;
        formData[sheet] = tableData;
        uiSchema[sheet] = tableUi;
      } else {
        // add sheet which has multiple rows
        let tableSchema = {
          title: sheet_name(sheet),
          type: "array",
          items: {
            type: "object",
            properties: {}
          }
        };
        let tableData = [{}];
        let tableUi = {
          "ui:options": {
            orderable: false
          },
          "items": {}
        };
        Object.keys(editingReport.sheets[sheet]).forEach(field => {
          let prop = getPropertyFromJson(editingReport, sheet, field);
          tableSchema.items.properties[field] = prop.schema;
          if (prop.ui && Object.keys(prop.ui).length > 0)
            tableUi["items"][field] = prop.ui;
          if (prop.data) {
            prop.data.forEach((value, index) => {
              if (tableData.length - 1 < index) {
                tableData.push({});
              }
              tableData[index][field] = value;

            });
          }
        });
        schema.properties[sheet] = tableSchema;
        formData[sheet] = tableData;
        uiSchema[sheet] = tableUi;
      }
    });

    this.setState({
      formData: formData,
      schema: schema,
      uiSchema: uiSchema
    });
  }

  getReport (id) {
    fetch('/report/' + id).then(data => {
      this.setState({
        editReport: data
      });
    }).catch(error => {
      this.props.showNotice('Raportti ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  getContracts () {
    fetch('/contracts/').then(data => {
      let ids = [];
      let names = [];
      data.forEach(contract => {
        ids.push(contract.id);
        names.push(contract.name);
      });
      this.setState({
        contracts: names,
        contractsID: ids,
      });
      ids.forEach(id => {
        this.getSites(id)
      })
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }

  getSites (contractId) {
    fetch('/constructionsites?contract=' + contractId).then(data => {
      let ids = [];
      let names = [];
      data.forEach(site => {
        ids.push(site.id);
        names.push(site.name);
      });
      this.setState({
        sites: {
          ...this.state.sites,
          [contractId]: names
        },
        sitesID: {
          ...this.state.sitesID,
          [contractId]: ids
        }
      });
    }).catch(error => {
      this.props.showNotice('Kohdetta ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  preparePOSTData (formData) {

    let report = {
      site_id: formData.constructionSite,
      last_modify: currentTime(),
      sheets: {}
    };

    for (let key in formData) {
      if (key === "contract" || key === "constructionSite") {
        continue;
      }
      report.sheets[key] = {};
      if (!Array.isArray(formData[key])) { // single value
        for (let field in formData[key]) {
          if (formData[key][field] != null) {
            report.sheets[key][field] = {};
            if (typeof formData[key][field] === "object") { // custom object, whose value is an array

              report.sheets[key][field]["value"] = [];
              Object.keys(formData[key][field]).forEach(subValue => {
                if (typeof formData[key][field][subValue] === "undefined")
                  report.sheets[key][field]["value"].push(null);
                else
                  report.sheets[key][field]["value"].push(formData[key][field][subValue]);
              });

            } else {
              if (typeof formData[key][field] !== "undefined")
                report.sheets[key][field]["value"] = formData[key][field];
            }
          }
        }
      } else { // multiple values for one field
        formData[key].forEach(column => {
          for (let field in column) {
            if (!report.sheets[key][field]) {
              report.sheets[key][field] = {};
              report.sheets[key][field]["value"] = [];
            }
            if (typeof column[field] === "undefined")
              report.sheets[key][field]["value"].push(null);
            else
              report.sheets[key][field]["value"].push(column[field]);
          }
        })
      }
    }
    return report;
  }

  onSubmit ({ formData }) {
    let report = this.preparePOSTData(formData);

    fetch('/report/' + this.state.editReport.id, 'PATCH', report).then((data) => {
      if (data) {
        this.setState({
          editReport: data
        });
        this.props.showMessage('saved successfully');
        this.setForm(data, this.state.sitesID, this.state.sites);
      }
    }).catch(error => {
      console.log(error);
      this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
    });
  }

  confirmDelete (event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko tämä (' + this.state.editReport.template.name + ') raportti?', this.delete);
  }

  delete () {
    fetch('/report/' + this.state.editReport['id'] + '/', 'DELETE').then(data => {
      this.props.showNotice('Raportti poistettu', 'Ok');
      this.setState({ redirect: true });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Raportti poisto epäonnistui', 'Error');
    });
  }

  handleChange (data) {
    let schema = { ...this.state.schema };

    const newSchema = Object.assign({}, schema);
    const newFormData = Object.assign({}, data.formData);
    if (this.state.formData.contract !== newFormData.contract) {
      newSchema.properties.constructionSite.enum = this.state.sitesID[data.formData.contract];
      newSchema.properties.constructionSite.enumNames = this.state.sites[data.formData.contract];
      newSchema.properties.constructionSite.default = this.state.sitesID[data.formData.contract][0];
      newFormData.constructionSite = this.state.sitesID[data.formData.contract][0];
    }

    this.setState({
      formData: newFormData,
      schema: newSchema
    });
  }

  sendCopies () {
    let report = this.preparePOSTData(this.state.formData);
    // request backend to send copies (backend would save this report before sending emails)
    fetch('/report/' + this.state.editReport.id + '/send', 'POST', report).then((data) => {
      if (data) {
        this.props.showMessage('copies sent successfully');
      }
    }).catch(error => {
      console.log(error);
      this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/reports/' push />;
    if (this.state.schema == null) {
      return (
        <div className="main-client-container">
          <h2>Ladataan tietoja...</h2>
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div className="main-client-container">
        <h1>Muokkaa raportti</h1>
        <h2>{this.state.editReport.template.title}</h2>
        <h3>{this.state.editReport.template.name}</h3>
        <h4>last modify time: {this.state.editReport.last_modify.replace('T', ' ')}</h4>
        <Form schema={this.state.schema} uiSchema={this.state.uiSchema} validator={validator}
          onSubmit={this.onSubmit} formData={this.state.formData}
          onChange={this.handleChange.bind(this)}
          showErrorList={false}>
          <div>
            <button className="button-primary" type="submit">Tallenna</button>
            <Link to="/reports">
              <button className="button-outline">Peruuta</button>
            </Link>
            <button className='float-right red-text button-outline' onClick={this.confirmDelete}>
              Poista
            </button>
          </div>
        </Form>
        <button className="button-primary" onClick={this.sendCopies}>Send Report Copies</button>
      </div>
    );
  }
}

export default connect(state => ({
}), { showNotice, showMessage, showConfirm })(ReportEdit);