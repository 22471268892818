import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showMessage } from '../../../../src/message/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { fetch } from '../utils';
import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';

class OrganizationNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: null,
      uiSchema: {},
      formData: {},
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount () {
    this.setForm();
  }

  setForm () {
    const schema = {
      type: "object",
      required: ["name"],
      properties: {
        name: { type: "string", title: "Nimi:" },
        companyCode: { type: "string", title: "Y-Tunnus:" },
      },
    };

    this.setState({
      schema: schema
    });
  }

  onSubmit ({ formData }) {
    this.setState({ loading: true });

    const organization = {
      name: formData.name,
      company_code: formData.companyCode
    };

    fetch('/organizations', 'POST', organization).then(async data => {
      this.props.showNotice('lisätty', 'Ok');
      this.setState({ redirect: true });
    }).catch(error => {
      this.setState({
        loading: false,
        formData: formData
      });

      this.props.showMessage('Virhe', 'Yhteys virhe', 'Error');
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/organizations' push />;

    if (this.state.schema == null) {
      return (
        <div className='main-client-container'>
          <div className='loader'></div>
        </div>
      );
    }

    return (
      <div className='main-client-container'>
        <h1>Lisää organisaatio</h1>
        <Form schema={this.state.schema} uiSchema={this.state.uiSchema} validator={validator}
          onSubmit={this.onSubmit} formData={this.state.formData}
          onChange={this.onChange} showErrorList={false}>
          {this.state.loading ?
            <div className='loader' />
            :
            <div>
              <input className='button-primary' type='submit' value='Lisää' />
              <Link className='button-primary' to='/organizations/'>
                <button className='button-outline'>Peruuta</button>
              </Link>
            </div>
          }
        </Form>
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
  contracts: state.contractSelect.get('contracts'),
}), { showNotice, showMessage })(OrganizationNew);
