export const setMessage = (message) => ({
  type: 'SET_MESSAGE',
  message
});

export const setMessages = (messages) => ({
  type: 'SET_MESSAGES',
  messages
});

export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES'
});

export const removeMessage = messageId => ({
  type: 'REMOVE_MESSAGE',
  messageId
});
