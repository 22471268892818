import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice } from '../../../../src/notice/Actions';
import { showMessage } from '../../../../src/message/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { removeContract } from '../../../../src/contractSelect/Actions';
import { fetch } from '../utils';
import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';

class ContractsNew extends React.Component {

  constructor(props) {
    super(props);

    this.getContract(parseInt(this.props.params.id, 10));

    this.schema = null;

    this.state = {
      editContract: null,
      redirect: false
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.delete = this.delete.bind(this);
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (this.schema === null && nextState.editContract != null) {
      this.setForm(nextState.editContract);
    }
  }

  setForm (editingContract) {
    this.schema = {
      type: "object",
      required: ["name"],
      properties: {
        name: { type: "string", title: "Nimi:" },
        year: { type: "integer", title: "Kausi:" },
        orderer: { type: "string", title: "Tilaaja:" }
      }
    };

    this.uiSchema = {
      name: {
        "ui:autofocus": true
      },
    }

    this.formData = {
      name: editingContract['name'],
      year: editingContract['year'],
      orderer: editingContract['orderer']
    };
  }

  onSubmit = ({ formData }) => {
    const contract = {
      id: this.state.editContract['id'],
      name: formData.name,
      year: formData.year,
      orderer: formData.orderer
    };

    fetch('/contracts/' + contract['id'] + '/', 'PATCH', contract).then(data => {
      this.props.showNotice('Urakan muokkaaminen onnistui', 'Ok');
      this.setState({
        redirect: true
      });
    }).catch(error => {
      if (error.message === '400') {
        this.props.showMessage('Virhe', 'Palvelin ei hyväksynyt annettua tietoa (esim. urakka on jo olemassa)', 'Warning');
      }
      else {
        this.props.showNotice('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
      }
    });
  }

  getContract (id) {
    fetch('/contracts/' + id + '/').then(data => {
      this.setState({
        editContract: data,
      });
    }).catch(error => {
      this.props.showNotice('Urakkaa ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  confirmDelete (event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko tämä (' + this.state.editContract['name'] + ') kohde?', this.delete);
  }


  delete () {
    fetch('/contracts/' + this.state.editContract['id'], 'DELETE').then(data => {
      this.props.showNotice('Urakka poistettu', 'Ok');
      this.props.removeContract(this.state.editContract['id']);
      this.setState({ redirect: true });
    }).catch(error => {
      if (error.message === '409') {
        this.props.showMessage('Virhe', 'Urakkaan kuuluu kohde tai useampi', 'Error');
      }
      else {
        this.props.showMessage('Virhe', 'Urakan poisto epäonnistui', 'Error');
      }
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/contracts' push />;
    if (this.schema == null) {
      return (
        <div className="main-client-container">
          <h2>Ladataan tietoja...</h2>
          <div className="loader"></div>
        </div>
      );
    }
    return (
      <div className="main-client-container">
        <h1>Muokkaa urakkaa</h1>
        <h2>{this.state.editContract['name']}</h2>
        <Form schema={this.schema} uiSchema={this.uiSchema} validator={validator}
          onSubmit={this.onSubmit} formData={this.formData}
          showErrorList={false}>
          <div>
            <button className="button-primary" type="submit">Tallenna</button>
            <Link to="/contracts">
              <button className="button-secondary">Peruuta</button>
            </Link>
          </div>
          <button className="float-right red-text button-outline" onClick={this.confirmDelete}>
            Poista
          </button>
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
}), { showNotice, showConfirm, showMessage, removeContract })(ContractsNew);
