import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMessage } from '../../../../src/message/Actions';
import List from '../../../../src/list/List';
import './ConstructionSites.css';


class ConstructionSites extends React.Component {
  
  render() {
    return (
      <div className="main-client-container">
        <h1>Kohteet</h1>
        <div className="center">
          <Link to={'/sites/new'}>
            <button>
              Uusi kohde
            </button>
          </Link>
        </div>
        { this.props.selectedContract != null ?
          <List many={10}
                emptyText={'Ei kohteita'}
                header={[
                  'Urakka',
                  'Nimi',
                  'Kohdetunnus',
                  'Tie',
                  'Massatyyppi',
                  'Toimenpidetyyppi',
                  'kg/m²',
                  ''
                ]}
                fields={[
                  'contract.name',
                  'name',
                  'custom_id',
                  'road_number',
                  'mass_type',
                  'operation_type',
                  'flatness',
                  'button#Muokkaa#/site/{id}'
                ]}
                data={this.props.constructionSites} />
          :
          <h2 className='center'>Valitse ensiksi urakka</h2>
        }
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
}), { showMessage })(ConstructionSites);
