import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { useMap, MapContainer, Marker, Popup, TileLayer, useMapEvent } from 'react-leaflet';
import MapTiles from '../../../../src/mapTiles/MapTiles.js';
import { showNotice } from '../../../../src/notice/Actions';
import { toETRSTM35FIN } from '../utils.js';
import './CoatingPlantSelect.css'
import 'leaflet/dist/leaflet.css';

const Selector = props => {
  return (
    <div>
      <label>{props.label}</label>
      {(props.name !== '' ? props.name + ' ' : '') + 'lat: ' + (props.latitude ? Math.round(props.latitude * 10000) / 10000 : '-') + ' lon: ' +
        (props.longitude ? Math.round(props.longitude * 10000) / 10000 : '-')}
      <button id='coating-plant-select-button' className="button-outline" onClick={props.toggleSetting}>
        Aseta sijainti
      </button>
    </div>
  );
}

const CoatingPlantSelect = (properties) => {
  const props = properties;

  const position = [64.1, 26.5];
  const zoom = 6;
  let watchID;
  let map;

  const [setting, setSetting] = useState(false);
  const [yourLatitude, setYourLatitude] = useState(null);
  const [yourLongitude, setYourLongitude] = useState(null);
  const [coatingPlantLatitude, setCoatingPlantLatitude] = useState(null);
  const [coatingPlantLongitude, setCoatingPlantLongitude] = useState(null);
  const [settedCoatingPlantLatitude, setSettedCoatingPlantLatitude] = useState(null);
  const [settedCoatingPlantLongitude, setSettedCoatingPlantLongitude] = useState(null);
  const [settedName, setSettedName] = useState('');
  const [mapTilesUrl, setMapTilesUrl] = useState('');
  const [mapTilesAttribution, setMapTilesAttribution] = useState('');
  const [maxZoom, setMaxZoom] = useState(16);
  const [height, setHeight] = useState(window.innerHeight * 0.6);
  const [title, setTitle] = useState('Valitse aseman sijainti');
  const [label, setLabel] = useState((props.index + 1) + '. Asema');
  const [icon, setIcon] = useState('coating_plant_map.gif');
  const [iconSize, setIconSize] = useState([33, 50]);
  const [name, setName] = useState('');

  useEffect(() => {
    if (props.macadam) {
      setTitle('Valitse montun sijainti');
      setLabel((props.index + 1) + '. Monttu');
      setIcon('pit_map.png');
      setIconSize([50, 18]);
    }
    window.addEventListener("resize", updateDimensions)

    if (!navigator.geolocation) {
      return;
    }

    watchID = navigator.geolocation.watchPosition(position => {
      setYourLatitude(position.coords.latitude);
      setYourLongitude(position.coords.longitude);
    }, error => {
      return;
    }, { enableHighAccuracy: true });

    return () => {
      navigator.geolocation.clearWatch(watchID);
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    const height = window.innerHeight * 0.6;
    setHeight(height);
  }

  const toggleSetting = () => {
    setSetting(!setting);
  }

  const setCoatingPlant = (event) => {
    setCoatingPlantLatitude(event.latlng.lat);
    setCoatingPlantLongitude(event.latlng.lng);
  }

  const acceptCoatingPlant = () => {
    if (coatingPlantLatitude == null ||
      coatingPlantLongitude == null) {
      props.showNotice('Valitse ensin kartalta aseman paikka', 'Warning');
      return;
    }

    setSettedCoatingPlantLatitude(coatingPlantLatitude);
    setSettedCoatingPlantLongitude(coatingPlantLongitude);
    setSettedName(name);
    setSetting(!setting);

    props.setCoatingPlant(coatingPlantLatitude, coatingPlantLongitude,
      name, props.index);
  }

  const changeName = (event) => {
    setName(event.target.value);
  }

  function MapControl() {
    map = useMap();

    useMapEvent('click', (event) => {
      setCoatingPlant(event);
    });

    const mapCenter = map.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (mapTilesUrl !== mapTiles.url) {
      setMapTilesUrl(mapTiles.url);
      setMapTilesAttribution(mapTiles.attribution);
      setMaxZoom(mapTiles.maxZoom);
    }
  }

  if (!setting) {
    return (
      <Selector name={settedName}
        latitude={settedCoatingPlantLatitude}
        longitude={settedCoatingPlantLongitude}
        toggleSetting={toggleSetting}
        label={label} />
    );
  }

  return (
    <div>
      <Selector name={settedName}
        latitude={settedCoatingPlantLatitude}
        longitude={settedCoatingPlantLongitude}
        toggleSetting={toggleSetting}
        label={label} />
      <div onClick={toggleSetting} className='modal'>
        <div id='coating-plant-select-modal' onClick={(e) => { e.stopPropagation(); }}>
          <h4 className='center'>{title}</h4>
          <div style={{ height: height }}>
            <MapContainer id='map-area' center={position} zoom={zoom}
              maxZoom={maxZoom}>
              <MapControl />
              <TileLayer url={mapTilesUrl}
                attribution={mapTilesAttribution}
                maxZoom={maxZoom} />
              {coatingPlantLatitude != null ? (
                <Marker position={[coatingPlantLatitude, coatingPlantLongitude]}
                  icon={new L.Icon({
                    iconUrl: '../' + icon,
                    iconSize: iconSize,
                    iconAnchor: [iconSize[0] / 2, iconSize[1]],
                    popupAnchor: [null, -50]
                  })}>
                  <Popup autoPan={false}>
                    <span>{coatingPlantLatitude}, {coatingPlantLongitude}</span>
                  </Popup>
                </Marker>
              ) : null}
              {yourLatitude != null ? (
                <Marker position={[yourLatitude, yourLongitude]}
                  icon={new L.Icon({
                    iconUrl: '../your_location.gif',
                    iconSize: [18, 43],
                    iconAnchor: [9, 43],
                    popupAnchor: [null, -43]
                  })}>
                  <Popup>
                    <span>{yourLatitude}, {yourLongitude}</span>
                  </Popup>
                </Marker>
              ) : null}
            </MapContainer>
          </div>
          <br />
          <label htmlFor='name'>Nimi:</label>
          <input id='name' type='text' value={name} onChange={changeName.bind(this)} />
          <div id='coating-plant-select-bottom' className='row'>
            <div className='column'>
              <button onClick={acceptCoatingPlant}>
                Aseta
              </button>
            </div>
            <div className='column'>
              <button className="button-outline" onClick={toggleSetting}>
                Peruuta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
}), { showNotice })(CoatingPlantSelect);
