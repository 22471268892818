import React from "react";
import { Link, Redirect } from "react-router-dom";
import Form from "@rjsf/core";
import { fetch, getFieldTypes, getPropertyFromJson } from '../utils';
import { connect } from "react-redux";
import { showMessage } from "../../../../src/message/Actions";
import { showNotice } from "../../../../src/notice/Actions";
import { showConfirm } from "../../../../src/confirm/Actions";
import * as ReactDOM from "react-dom";
import validator from '@rjsf/validator-ajv8';

class TemplateEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectURL: '/templates/',
      initialize: false,
      addDefaultSheets: false,
      basicSchema: {
        type: "object",
        properties: {
          name: {
            title: "template name",
            type: "string"
          },
          title: {
            title: "template title",
            type: "string"
          },
          form_id: {
            title: "template form id",
            type: "string"
          }
        }
      },
      basicFormData: null,
      schema: [],
      uiSchema: [],
      formData: [],
      updateSheetIndex: null
    };

    if (this.props.params.hasOwnProperty("id")) {
      this.getTemplate(parseInt(this.props.params.id, 10));
    } else {
      this.state.addDefaultSheets = true;
    }

    this.confirmDelete = this.confirmDelete.bind(this);
    this.delete = this.delete.bind(this);
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (this.state.addDefaultSheets) {
      this.initializeSchemas(this.getDefaultTemplate());
      this.setState({ addDefaultSheets: false });
    }

    if (nextState.schema.length !== this.state.schema.length) {
      this.setForms()
    }
  }

  getDefaultTemplate () {
    return {
      sheets: {
        "ObjectOIKEINVÄÄRIN": {
          "OIKEIN YHT.": {
            type: "Number_inline"
          },
          "VÄÄRIN YHT.": {
            type: "Number_inline"
          }
        }
      }
    };
  }

  getTemplate (id) {
    // initialize schema, ui and data from database
    fetch('/template/' + id).then(data => {
      this.initializeSchemas(data);
    }).catch(error => {
      console.log(error);
      this.props.showNotice('Template ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  getSchemaTemplate () {
    return {
      name: "",
      type: "",
      multiple: false,
      fields: {
        type: "object",
        properties: {}
      }
    };
  }

  initializeSchemas (data) {
    let basicData = {};
    if (data.hasOwnProperty("name")) {
      basicData.name = data.name;
    }
    if (data.hasOwnProperty("title")) {
      basicData.title = data.title;
    }
    if (data.hasOwnProperty("form_id")) {
      basicData.form_id = data.form_id;
    }

    let schemas = [];
    let uiSchemas = [];
    let formDatas = [];

    if (data.hasOwnProperty("sheets")) {
      Object.keys(data.sheets).forEach(sheetKey => {
        let schemaTemplate = this.getSchemaTemplate();
        let ui = {};
        let formdata = {};

        // extract sheet type
        if (sheetKey.startsWith("Object")) { // custom field sheet
          schemaTemplate.type = "object";
          // extract custom field name
          schemaTemplate.name = sheetKey.replace("Object", "");
        } else {
          let name = sheetKey.split("_");
          // extract sheet name
          if (name[1] === "blank")
            schemaTemplate.name = "";
          else
            schemaTemplate.name = name[1];
          if (name[2] === "Formula") { // formula sheet
            schemaTemplate.type = "formula"
          } else if (name[0].startsWith("multiple")) {
            schemaTemplate.type = "sheet";
            schemaTemplate.multiple = true;
          } else {
            schemaTemplate.type = "sheet";
            schemaTemplate.multiple = false;
          }
        }

        // input fields schema, ui and data(default values)
        Object.keys(data.sheets[sheetKey]).forEach(fieldKey => {
          // do not show these 2 formula fields because users cannot edit them
          // it will be shown as disabled inputs at the end of its sheet
          if ((fieldKey === "OIKEIN YHTEENSÄ" || fieldKey === "VÄÄRIN YHTEENSÄ") && data.sheets[sheetKey][fieldKey].type.startsWith("Formula"))
            return; // forEach does not have continue,so use return instead

          let property = getPropertyFromJson(data, sheetKey, fieldKey, true);
          schemaTemplate.fields.properties[fieldKey] = property.schema;
          ui[fieldKey] = property.ui;
          formdata[fieldKey] = property.data;
        });
        // add into array
        schemas.push(schemaTemplate);
        uiSchemas.push(ui);
        formDatas.push(formdata);
      });
    }

    this.setState({
      basicFormData: basicData,
      schema: schemas,
      uiSchema: uiSchemas,
      formData: formDatas,
    });
    //
    // this.setState({
    //     initialize: true
    // });
  }

  collapse (e) {
    let form = e.target.parentNode.nextSibling;

    if (form.className.indexOf("hidden") < 0)
      form.className = form.className + " hidden";
    else
      form.className = form.className.replace("hidden", "");
  }

  close (e) {
    let parent = e.target.parentNode.parentNode;
    parent.className = parent.className + " hidden";
  }

  getSheetName (name, type) {
    let sheetName = null;
    switch (type) {
      case "sheet": {
        sheetName = (name && name.trim !== "") ? name : "SUB-SHEET";
        break;
      }
      case "formula": {
        sheetName = ((name && name.trim !== "") ? name : "SUB-SHEET") + " (OIKEIN/VÄÄRIN)";
        break;
      }
      case "object": {
        sheetName = (name && name.trim !== "") ? name + " (CUSTOM FIELD)" : "CUSTOM FIELD";
        break;
      }
      default:
        break;
    }
    return sheetName;
  }

  openEditSheet (e) {
    let sheetNum = e.target.parentNode.parentNode.getAttribute("sheetNum");
    let name = this.state.schema[sheetNum].name;
    let sheetType = this.state.schema[sheetNum].type;

    let editsheetDOM = document.getElementById("edit-sheet");
    editsheetDOM.setAttribute("sheetNum", sheetNum);

    let nameDOM = editsheetDOM.getElementsByTagName("input")[0];
    nameDOM.value = name;

    // sheet needs to be clarified as Single_ or Multiple_
    // multiple means each field has multiple values
    // formula sheet starts with Formula_
    // custom field sheet starts with Object_, so it does not need multiple property
    let multipleDOM = document.getElementById("multiple");
    if (sheetType === "sheet") {
      let multiple = this.state.schema[sheetNum].multiple;
      multipleDOM.checked = multiple;
      multipleDOM.className = "";
    } else {
      multipleDOM.className = "hidden";
    }

    editsheetDOM.className = editsheetDOM.className.replace("hidden", "");
  }

  editSheet (e) {
    // get info from DOM and state.schema
    let parentDOM = e.target.parentNode.parentNode;
    let sheetNum = parentDOM.getAttribute("sheetNum");
    let name = parentDOM.childNodes[0].value;
    let multiple = parentDOM.childNodes[1].checked;
    let sheetType = this.state.schema[sheetNum].type;

    // update schema
    let schema = this.state.schema;
    schema[sheetNum].name = name;

    if (sheetType === "sheet") {
      schema[sheetNum].multiple = multiple;
    }

    this.setState({ schema: schema });

    // update sheet display name in webview
    let sheetDOM = this.getSheetDOMBySheetNum(sheetNum);
    sheetDOM.childNodes[0].childNodes[0].childNodes[0].innerHTML = this.getSheetName(name, sheetType);

    // close editing window
    parentDOM.className = parentDOM.className + " hidden";
  }

  deleteSheet (e) {
    let sheetDOM = e.target.parentNode.parentNode;
    let sheetNum = sheetDOM.getAttribute("sheetNum");
    sheetDOM.parentNode.removeChild(sheetDOM);
    delete this.state.schema[sheetNum];
    delete this.state.uiSchema[sheetNum];
    delete this.state.formData[sheetNum];
  }

  createFieldTypes = (sheetType) => {
    let options = [];
    let types = getFieldTypes();
    if (sheetType === "sheet") { // regular sheets allow basic fields + custom fields
      for (let key in types) {
        options.push(<option value={types[key]}>{key}</option>)
      }
      let customList = this.getCustomSheets();
      for (let key in customList) {
        options.push(<option value={key}>{customList[key]}</option>)
      }
    } else if (sheetType === "formula") { // formular sheets only allow input for OIKEIN/VÄÄRIN
      // find ObjectOIKEINVÄÄRIN index in schema
      for (let index in this.state.schema) {
        if (this.state.schema[index].name === "OIKEINVÄÄRIN" && this.state.schema[index].type === "object") {
          options.push(<option value={"Object" + index}>OIKEIN-VÄÄRIN</option>);
          break;
        }
      }
      options.push(<option value="Description">Description</option>);
      options.push(<option value="Title1">Title</option>);
    } else if (sheetType === "object") {// custom field sheets only allow basic fields
      for (let key in types) {
        if (key === "Custom")
          continue;
        options.push(<option value={types[key]}>{key}</option>)
      }
    }

    return options;
  };

  createFieldNames = (fields) => {
    let options = [];
    for (let index in fields) {
      options.push(<option value={fields[index]}>{fields[index]}</option>)
    }
    return options;
  };

  getCustomSheets () {
    let customList = {};
    for (let key in this.state.schema) {
      if (this.state.schema[key].type === "object")
        customList["Object" + key] = this.state.schema[key].name;
    }
    return customList;
  }

  getSheetDOMBySheetNum (sheetNum) {
    let sheetList = document.getElementsByClassName("sub-sheet");
    if (sheetList.length > 0) {
      for (let index in sheetList) {
        if (sheetList[index].getAttribute("sheetNum") === sheetNum) {
          return sheetList[index].parentNode;
        }
      }
    }

    return null;
  }

  updateFormData (sheetNum, formData) {
    sheetNum = parseInt(sheetNum);

    this.setState(state => {
      const forms = state.formData.map((item, j) => {
        if (j === sheetNum) {
          return formData;
        } else {
          return item;
        }
      });
      return {
        forms
      };
    });
  }

  updateSheetDom (schema, sheetNum) {
    if (schema === null || schema === undefined)
      schema = this.state.schema;

    let DOM = null;

    if (sheetNum === -1) {// add new sheet
      DOM = document.createElement("div");
      document.getElementById("sheets").appendChild(DOM);
      sheetNum = schema.length - 1;

    } else { // update current sheet
      DOM = this.getSheetDOMBySheetNum(sheetNum);
      if (DOM) { // clear DOM before render new content
        // ReactDOM.render(null,DOM);
      } else { // if did not find DOM, create a new one
        DOM = document.createElement("div");
        document.getElementById("sheets").appendChild(DOM);
      }
    }

    let sheetType = schema[sheetNum].type;

    const newSheetDOM = (<div sheetNum={sheetNum} className="sub-sheet">
      <div className="row-flex">
        <div
          onClick={event => this.collapse(event)}>{this.getSheetName(schema[sheetNum].name, sheetType)}</div>
        <button className="edit-btn" onClick={event => this.openEditSheet(event)}>edit sheet</button>
        <button className="delete-btn" onClick={event => this.deleteSheet(event)}>delete</button>
      </div>
      <div className="form">
        <Form schema={this.state.schema[sheetNum].fields} validator={validator}
          uiSchema={this.state.uiSchema[sheetNum]}
          formData={this.state.formData[sheetNum]}
          onChange={({ formData }) => this.updateFormData(sheetNum, formData)}
          showErrorList={false}>
          <div>
            <button className="button-primary hidden" type="submit">submit</button>
          </div>
        </Form>
        {sheetType === "formula" ? <div>
          <div className="row-flex">
            <input className="width-40" type="number" placeholder="OIKEIN YHTEENSÄ" disabled="true" />
            <input className="width-40" type="number" placeholder="VÄÄRIN YHTEENSÄ" disabled="true" />
          </div>
          <p className="description">will be calculated after submitting report</p>
        </div> : null}
        <div className="row-flex">
          <select className="width-33" name="field-name" >
            <option value="">New Field</option>
            {this.createFieldNames(Object.keys(schema[sheetNum].fields.properties))}
          </select>
          <select className="width-33" name="type">
            {this.createFieldTypes(sheetType)}
          </select>
          <button className="add-btn-small" onClick={event => this.openEditField(event)}>edit field</button>
        </div>
      </div>
    </div>);

    ReactDOM.render(newSheetDOM, DOM);

    this.setState({
      updateSheetIndex: null
    });
  }

  getSheetDom (sheetNum) {
    let sheetType = this.state.schema[sheetNum].type;

    const newSheetDOM = (<div sheetNum={sheetNum} className="sub-sheet">
      <div className="row-flex">
        <div
          onClick={event => this.collapse(event)}>{this.getSheetName(this.state.schema[sheetNum].name, sheetType)}</div>
        <button className="edit-btn" onClick={event => this.openEditSheet(event)}>edit sheet</button>
        <button className="delete-btn" onClick={event => this.deleteSheet(event)}>delete</button>
      </div>
      <div className="form">
        <Form schema={this.state.schema[sheetNum].fields} validator={validator}
          uiSchema={this.state.uiSchema[sheetNum]}
          formData={this.state.formData[sheetNum]}
          showErrorList={false}>
          <div>
            <button className="button-primary hidden" type="submit">submit</button>
          </div>
        </Form>
        {sheetType === "formula" ? <div>
          <div className="row-flex">
            <input className="width-40" type="number" placeholder="OIKEIN YHTEENSÄ" disabled="true" />
            <input className="width-40" type="number" placeholder="VÄÄRIN YHTEENSÄ" disabled="true" />
          </div>
          <p className="description">will be calculated after submitting report</p>
        </div> : null}
        {(this.state.schema[sheetNum].name === "OIKEINVÄÄRIN" && this.state.schema[sheetNum].type === "object") ? null :
          <div className="row-flex">
            <select className="width-33" name="field-name" >
              <option value="">New Field</option>
              {this.createFieldNames(Object.keys(this.state.schema[sheetNum].fields.properties))}
            </select>
            <select className="width-33" name="type">
              {this.createFieldTypes(sheetType)}
            </select>
            <button className="add-btn-small" onClick={event => this.openEditField(event)}>edit field</button>
          </div>}
      </div>
    </div>);

    return newSheetDOM;
  }

  openChooseSheetType () {
    document.getElementById("sheet-type").className = document.getElementById("sheet-type").className.replace("hidden", "");
  }

  chooseSheetType () {
    let sheetType = document.getElementById("sheet-type-select").value;
    let schemaTemplate = this.getSchemaTemplate();
    schemaTemplate.type = sheetType;

    this.addSheet(schemaTemplate)
  }

  addSheet (newSheetSchema) {
    // close sheet type selector
    document.getElementById("sheet-type").className = document.getElementById("sheet-type").className + " hidden";
    // update schema, ui and data
    let newSchema = this.state.schema.concat(newSheetSchema);
    let newUi = this.state.uiSchema.concat({});
    let newData = this.state.formData.concat({});
    this.setState({
      schema: newSchema,
      uiSchema: newUi,
      formData: newData
    }
      // , () => {this.updateSheetDom(null, -1)}
    );

  }

  editField (form, sheetNum, oldFieldName, type) {
    // parameters we need
    let name = form.name;
    const newSchema = this.state.schema[sheetNum];
    const newUi = this.state.uiSchema[sheetNum];
    const newData = this.state.formData[sheetNum];

    // update an extra field to force interface updating immediately
    if (newData.hasOwnProperty("#version"))
      newData["#version"] = newData["#version"] + 1;
    else
      newData["#version"] = 0;

    // get schema, ui and data
    let infoJson = {
      sheets: {
        fields: {
          [name]: {
            type: type
          }
        }
      }
    };
    if (form.options) {
      infoJson.sheets.fields[name].options = form.options;
    }
    if (type.startsWith("Object")) { // custom field
      let customSheetIndex = parseInt(type.substr(6));
      infoJson.sheets[type] = this.state.schema[customSheetIndex].fields.properties;
    }

    let property = getPropertyFromJson(infoJson, "fields", name, true);
    newUi[name] = property.ui;

    if (name === null || name === "") {
      alert("field name cannot be empty");
      return false;
    }

    if (oldFieldName === null || oldFieldName === "") { // create new field
      if (this.state.schema[sheetNum].fields.properties.hasOwnProperty(name)) {
        alert("field name already exists");
        return false;
      }
      newSchema.fields.properties[name] = property.schema;
    } else if (name === oldFieldName) { // update chosen field and the field name is the same
      newSchema.fields.properties[name] = property.schema;
    } else if (name !== oldFieldName) { // update chosen field but field name is changed
      let object = {};
      for (let key in newSchema.fields.properties) {
        if (key !== oldFieldName) {
          object[key] = newSchema.fields.properties[key];
        } else {
          object[name] = newSchema.fields.properties[key];
        }
      }
      newSchema.fields.properties = object;

      if (newUi.hasOwnProperty(oldFieldName))
        delete newUi[oldFieldName];
      if (newData.hasOwnProperty(oldFieldName))
        delete newData[oldFieldName];
    }

    this.setState(state => {
      const schemas = state.schema.map((item, j) => {
        if (j === sheetNum) {
          return newSchema;
        } else {
          return item;
        }
      });
      return {
        schemas
      };
    });

    this.setState(state => {
      const uis = state.uiSchema.map((item, j) => {
        if (j === sheetNum) {
          return newUi;
        } else {
          return item;
        }
      });
      return {
        uis
      };
    });
    this.updateFormData(sheetNum, newData);
    this.setState({
      fieldFormData: {}
    });

    // this.updateSheetDom(null, sheetNum);
    this.closeEditField()
  }

  deleteField (sheetNum, fieldName) {
    delete this.state.schema[sheetNum].fields.properties[fieldName];
    delete this.state.uiSchema[sheetNum][fieldName];
    delete this.state.formData[sheetNum][fieldName];

    // this.updateSheetDom(this.state.schema,sheetNum);

    this.closeEditField();
  }

  closeEditField () {
    ReactDOM.render(null, document.getElementById("edit-field"));
  }

  openEditField (e) {
    let sheetDOM = e.target.parentNode.parentNode.parentNode;
    let sheetnum = sheetDOM.getAttribute("sheetNum");
    let fieldname = e.target.parentNode.childNodes[0].value;
    let type = e.target.parentNode.childNodes[1].value;

    // check type is not null
    if (type == null) {
      alert("type is null");
      return false;
    }

    // initialize schema, uiSchema and formData according to its type
    const schema = {
      type: "object",
      require: ["name"],
      properties: {
        name: {
          type: "string",
          title: "field name"
        }
      }
    };
    const ui = {
      name: {
        "ui:placeholder": "field name"
      }
    };
    const data = {
      name: fieldname
    };
    if (type === "Radio" || type === "Checkbox") {
      schema.properties["options"] = {
        type: "array",
        items: {
          type: "string"
        }
      };

      // if this field already has options
      let field = this.state.schema[sheetnum].fields.properties[fieldname];
      if (field) {
        if (field.enum)
          data["options"] = field.enum;
        else if (field.items && field.items.enum)
          data["options"] = field.items.enum;
      }
    }

    if (type === "Description" || type === "Title1") {
      ui.name["ui:placeholder"] = "input texts here"
    }

    // create edit DOM
    const editDOM = (
      <div className="edit-form" sheetNum={sheetnum} fieldName={fieldname}>
        <Form className="form" schema={schema} validator={validator}
          formData={data} uiSchema={ui}
          // onChange={({formData})=>this.setState({fieldFormData: formData})}
          onSubmit={e => this.editField(e.formData, sheetnum, fieldname, type)}
          showErrorList={false} >
          <div className="row-flex">
            <button className="button-primary" type="submit">submit</button>
            <button onClick={e => this.closeEditField()}>cancel</button>
            {fieldname ? <button className="delete-btn" onClick={e => this.deleteField(sheetnum, fieldname)}>delete</button> : null}
          </div>
        </Form>
      </div>
    );

    // render to webview
    let divDOM = document.getElementById("edit-field");
    ReactDOM.render(editDOM, divDOM);
  }

  onSubmit () {
    let templateJson = {
      name: this.state.basicFormData.name,
      title: this.state.basicFormData.title,
      form_id: this.state.basicFormData.form_id,
      sheets: {
      }
    };

    for (let index in this.state.schema) {
      let sheet = this.state.schema[index];
      let sheetName = null;
      switch (sheet.type) {
        case "sheet":
          sheetName = (sheet.multiple ? "multiple" : "single") + index + "_" + (sheet.name === "" ? "blank" : sheet.name);
          break;
        case "formula":
          sheetName = "single" + index + "_" + (sheet.name === "" ? "blank" : sheet.name) + "_Formula";
          break;
        case "object":
          sheetName = "Object" + (sheet.name === "" ? "#" + [index] : sheet.name);
          break;
        default:
          break;
      }

      let propertiesString = JSON.stringify(sheet.fields.properties);
      let propertiesObject = JSON.parse(propertiesString);

      Object.keys(propertiesObject).forEach(fieldName => {
        propertiesObject[fieldName].type = propertiesObject[fieldName].typeCache;
        delete propertiesObject[fieldName].typeCache;
      });

      templateJson.sheets[sheetName] = propertiesObject;

      // add default field for formula sheets
      if (sheet.type === "formula") {
        let rowNum = Object.keys(propertiesObject).length;
        templateJson.sheets[sheetName]["OIKEIN YHTEENSÄ"] = {
          type: "Formula_inline",
          function: "IF(SUM(D1:E" + rowNum + "),SUM(D1:D" + rowNum + ")/SUM(D1:E" + rowNum + ")*100&\"%\",\"\")"
        };
        templateJson.sheets[sheetName]["VÄÄRIN YHTEENSÄ"] = {
          type: "Formula_inline",
          formula: "IF(SUM(D1:E" + rowNum + "),SUM(E1:E" + rowNum + ")/SUM(D1:E" + rowNum + ")*100&\"%\",\"\")"
        };
      }

      // set default values
      let formData = this.state.formData[index];
      if (formData !== null) {
        Object.keys(formData).forEach(fieldName => {
          if (formData[fieldName] !== null && formData[fieldName] !== undefined) {
            if (Array.isArray(formData[fieldName])) {
              let arrayString = "";
              for (let s in formData[fieldName]) {
                arrayString = arrayString + s + "|";
              }
              if (arrayString.endsWith("|"))
                arrayString = arrayString.substring(0, arrayString.length - 1);
              templateJson.sheets[sheetName][fieldName].value = arrayString;
            } else {
              templateJson.sheets[sheetName][fieldName].value = formData[fieldName];
            }
          }
        })
      }
    }

    if (this.props.params.hasOwnProperty("id")) {
      this.patchTemplate(parseInt(this.props.params.id, 10), templateJson);
    } else {
      this.postTemplate(templateJson);
    }
  }

  postTemplate (templateJson) {
    fetch('/template/', 'POST', templateJson).then((data) => {
      if (data) {
        this.setState({
          redirect: true,
          redirectURL: "/template/" + data.id
        });
      } else {
        this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
      }
    }).catch(error => {
      console.log(error);
      this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
    });
  }

  patchTemplate (id, templateJson) {
    fetch('/template/' + id, 'PATCH', templateJson).then((data) => {
      if (data) {
        this.setState({
          redirect: true,
          redirectURL: "/template/" + data.id
        });
      } else {
        this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
      }
    }).catch(error => {
      console.log(error);
      this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
    });
  }

  delete () {
    fetch('/template/' + parseInt(this.props.params.id, 10) + '/', 'DELETE').then(data => {
      this.props.showNotice('Template poistettu', 'Ok');
      this.setState({
        redirect: true,
        redirectURL: "/templates/"
      });
    }).catch(error => {
      console.log(error);
      this.props.showMessage('Virhe', 'Template poisto epäonnistui', 'Error');
    });
  }

  confirmDelete (event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko tämä (' + this.state.basicFormData.name + ') template?', this.delete);
  }

  setForms () {
    let forms = [];
    for (let index in this.state.schema) {
      let form = this.getSheetDom(index);
      forms.push(form);
    }
    return forms;
  }

  render () {
    if (this.state.redirect) return <Navigate to={this.state.redirectURL} push />;

    return (
      <div className="main-client-container">
        <h1>Uusi template</h1>
        <div>
          <div>
            Basic Info
          </div>
          <Form className="form" schema={this.state.basicSchema} validator={validator}
            formData={this.state.basicFormData} showErrorList={false}
            onChange={({ formData }) => this.setState({ basicFormData: formData })}>
            <div>
              <button className="button-primary hidden" type="submit">submit</button>
            </div>
          </Form>
        </div>

        <div id="sheets">
          {this.setForms()}
        </div>

        <button className="add-btn-large" onClick={event => this.openChooseSheetType()}>add sub sheet</button>
        <div className="row-flex">
          <button className="button-primary" type="submit" onClick={e => this.onSubmit()}>Lisää raportti</button>
          <Link to="/templates">
            <button className="button-outline">Peruuta</button>
          </Link>
          {this.props.params.hasOwnProperty("id") ?
            <button className='float-right red-text button-outline' onClick={this.confirmDelete}>Poista</button> :
            null
          }
        </div>

        <div className="edit-form hidden" id="sheet-type">
          <label htmlFor="sheet-type-select">Choose Sheet Type</label>
          <select name="sheet-type" id="sheet-type-select">
            <option value="sheet">normal sheet</option>
            <option value="formula">OIKEIN/VÄÄRIN</option>
            <option value="object">custom field</option>
          </select>
          <div className="row-flex">
            <button onClick={e => this.chooseSheetType()}>continue</button>
            <button onClick={e => this.close(e)}>cancel</button>
          </div>
        </div>

        <div className="edit-form hidden" id="edit-sheet" sheetNum="">
          <input type="text" name="name" placeholder="input sheet name e.g. MITTAUSKOHDE" />
          <label id="multiple"><input type="checkbox" name="multiple" /> allow fields have multiple values?</label>
          <div className="row-flex">
            <button onClick={e => this.editSheet(e)}>save</button>
            <button onClick={e => this.close(e)}>cancel</button>
          </div>
        </div>
        <div id="edit-field"></div>
      </div>
    )
  }
}

export default connect(state => ({
}), { showMessage, showNotice, showConfirm })(TemplateEdit);
